import Vue from "vue";
import Vuex from "vuex";
import moduleUser from "./modules/user";
import moduleUserSeats from "./modules/userSeats";
import moduleNotificationGroups from "./modules/notificationGroups";
import moduleWorkspace from "./modules/workspace";
import moduleBrandVoices from "./modules/brandVoices";
import moduleOrders from "./modules/orders";
import moduleTaskAssignment from "./modules/taskAssignment"
import moduleWebsites from "./modules/websites";
import moduleLinkBuilder from "./modules/linkBuilder";
import modulePageUrl from "./modules/pageUrl"
import toolsData from "@/jsonFiles/tools.json";
import axios from "@/axios";
import { Message } from "element-ui";
import moduleFolder from "./modules/folders";
import modulePricing from "./modules/pricing";
import modulePayments from "./modules/payments";
import moduleBriefings from "./modules/briefings";
import moduleCart from "./modules/cart";
import moduleReferral from "./modules/referral";
import moduleFastCheckout from "./modules/fastcheckout";
import router from "../routes/router";


Vue.use(Vuex);

const getDefaultState = () => {
  return {
    count: 0,
    isLoading: false,
    step: 1,
    tovs: [],
    languages: [],
    tools: [],
    toolsCategories: [], // only AI Writer
    countries: [],
    paramsRegistration: [],
    referralParams: [],
    priceExtracted: 0
  };
};

const store = new Vuex.Store({
  modules: {
    user: moduleUser,
    orders: moduleOrders,
    taskAssignment: moduleTaskAssignment,
    userSeats: moduleUserSeats,
    notificationGroups: moduleNotificationGroups,
    workspace: moduleWorkspace,
    brandVoices: moduleBrandVoices,
    folders: moduleFolder,
    websites: moduleWebsites,
    linkBuilder: moduleLinkBuilder,
    pageUrls: modulePageUrl,
    briefings: moduleBriefings,
    pricing:modulePricing,
    payments:modulePayments,
    cart:moduleCart,
    referral: moduleReferral,
    fastcheckout: moduleFastCheckout
  },
  state: getDefaultState(),
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },
    getStep(state) {
      return state.step;
    },
    getTools(state) {
      return state.tools;
    },
    getToolsCategories(state) {
      return state.toolsCategories;
    },
    getTovs(state) {
      return state.tovs;
    },
    getLanguages(state) {
      return state.languages;
    },
    getCountries(state) {
      return state.countries;
    },
    getParamsRegistration(state) {
      return state.paramsRegistration;
    },
    getReferralParams(state) {
      return state.referralParams
    },
    getPriceExtractedForHumanPrice(state, priceExtracted) {
      state.priceExtracted = priceExtracted;
    },
  },
  mutations: {
    async resetState(state) {
      Object.assign(state, getDefaultState());
    },
    increment(state) {
      state.count++;
    },
    setStep(state, step) {
      state.step = step;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsFavorite(state, {toolId, isFavorite}){
      state.tools.find(el => el.id === toolId).is_favorite = isFavorite;

    },
    setTools(state, tools) {
      // Filters down only the tools in the tools.json file
      state.tools = tools
        .filter(tool => toolsData.tools.some(t => t.slug === tool.slug))
        .map((tool) => {
          const toolExtraInfo = toolsData.tools.find(
            (t) => t.slug === tool.slug
          );
          //override macrocategory property only for custom tools
          if (tool.is_custom) {
            toolExtraInfo.macrocategory = 'custom_tools'
            if (state.user.user.extra_info && state.user.user.extra_info.custom_tool_name) {
              toolExtraInfo.name = `Custom ${state.user.user.extra_info.custom_tool_name} ${tool.name}`
            }
          }
          // Override tool properties with the ones from toolExtraInfo (if existing)
          return { ...tool, ...toolExtraInfo };
        });
    },
    setToolsCategories(state, categories) {
      state.toolsCategories = categories;
    },
    setTovs(state, tovs) {
      state.tovs = tovs;
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setParamsRegistration(state, paramsRegistration) {
      console.log(paramsRegistration);
      state.paramsRegistration = paramsRegistration;
    },
    setReferralParams(state, referralParams) {
      state.referralParams = referralParams;
    },
    setPriceExtractedForHumanPrice(state, priceExtracted) {
      state.priceExtracted = priceExtracted;
    },
  },
  actions: {
    async fetchTovs({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
      };

      const response = await axios.get("tools/show_user_tovs", {
        params: params,
      });

      const tovs = [];
      for (let i = 0; i < response.data.result[0].length; i++) {
        tovs.push({
          name: response.data.result[0][i].name,
          value: response.data.result[0][i].id,
          brand_voice: true,
          selected: response.data.default && i == 0 ? true : false,
        });
      }
      for (let j = response.data.result[1].length - 1; j >= 0; j--) {
        tovs.push({
          name: response.data.result[1][j].key,
          value: response.data.result[1][j].value,
          brand_voice: false,
          selected: false,
        });
      }

      // Update Vuex store with the retrieved data
      commit("setTovs", tovs);
    },

    async fetchLanguages({ getters, commit }) {
      const currentTool = getters.getTools.find((tool) => tool.slug === router.currentRoute.params.slug)
      const user = getters.getUser;
      const params = {
        user_id: user.id,
        template_id: currentTool.id,
      };

      const response = await axios.get("tools/show_language_codes", {
        params: params,
      });

      // Update Vuex store with the retrieved data
      let languages = response.data.result;
      for (let i = 0; i < languages.length; i++) {
        languages[i].key = `languages.${languages[i].key}` 
        
      }
      commit("setLanguages", languages);
    },

    async fetchCountries({ getters, commit }) {
      const user = getters.getUser;
      if(user){
        const params = {
          user_id: user?.id,
        };
        
        const response = await axios.get("services/orders/tools/trends/get_params", {
          params: params,
        });
        // Update Vuex store with the retrieved data
        const countries = response.data.data;
      
        commit("setCountries", countries);
      }
     
    },

    async fetchReferralParams({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
      };

      const response = await axios.get("features/affiliation/get_referral_params", {
        params: params,
      });

      // Update Vuex store with the retrieved data
      const referralParams = response.data.data;
      commit("setReferralParams", referralParams);
    },
    async toggleFavorite(context, {isFavorite, user_id, template_id}){
      let action = (isFavorite) ? "delete" : "create";

      const response = await axios.post("tools/update_user_favorite", {
          user_id: user_id,
          action: action,
          template_id: template_id
      });
      if(response?.data?.status !=="success"){
        Message({
          showClose: true,
          message: response?.data?.message,
          type: "error",
        });
      }else{
        Message({
          showClose: true,
          message: response?.data?.message,
          type: "success",
        });
      }
      context.commit("setIsFavorite",{toolId: template_id, isFavorite:response.data.is_favorite});
    },
    async fetchParamsRegistration({commit}) {
      const response = await axios.get("services/auth/get_register_params");
      const paramsRegistration = response.data.data;
      console.log('pr');
      console.log(response);
      commit("setParamsRegistration", paramsRegistration);
  }
  },
});

export default store;
