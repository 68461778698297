import { toRaw } from "vue";
import axios from "@/axios";
import { formatCurrency } from "../../../util/formatCurrency";
export default {
    async fetchFastcheckoutData({commit,getters, rootGetters}) {
        try {
            const res = await axios.get('features/fastcheckout/get-fastcheckout-data');
            const {appLanguages, currentLangKey} = res.data
            commit("setAppLanguages", appLanguages);
            commit("setCurrentLangKey", currentLangKey);
        } catch (error) {
            console.log('error:' + error.message)
        }
    },
    async fetchPlan({commit}, planId){
        const res = await axios.get('plan/'+ planId);
        const plan = {...res.data.data}

        const symbol =formatCurrency(plan.currency)[0]
        commit("cart/addToCart", plan, { root: true });
        commit("pricing/setUserCurrency", {
            code:plan.currency,
            symbol:symbol
        },{ root: true })
    },
    gtmStepCheckout({commit, getters, rootGetters},type) {
        try {
            const product = rootGetters['cart/getCart'][0];
            const discounts = rootGetters['payments/discounts']
           
            const currency = rootGetters['pricing/getUserCurrency']
            let tot;
            if(product.purchaseType === "human-one-shot" || product.purchaseType === "ai-credit-package"){
                tot = ((product.value/100) - discounts.discount).toFixed(2)
            } else {
                 tot = (product.prices[0].amount - discounts.discount).toFixed(2)
            }
            
            if (window.gtm) {
              window.gtm.ec_step_checkout(
                type,
                currency.code,
                tot,
                toRaw(product)
              );
            }
        } catch (error) {
            console.log('error:' + error.message);
        }
    },
    


}