const fadeIn = () => {
  document.getElementById('preloader').style.opacity = 1
  document.getElementById('preloader').style.display = 'block'
}

const fadeOut = () =>  {
  setTimeout(() => {
      document.getElementById('preloader').style.opacity = 0
      setTimeout(() => {         
        document.getElementById('preloader').style.display = 'none'
      }, 500);
    }, 1000);
}

export default { fadeIn, fadeOut }
