import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced:true,
    state(){
        return {
            step: 0,
            totalSteps: 2,
            customerType: "individual",
            appLanguages:{},
            currentLangKey:'en',
            freeTrial:false,
            emailMatch:false,
            userEmail:'',
            confirmEmail:'',
            valid:false,
            form:{},
            freeTrialFinalAgreement:false
        };
    },
    actions,
    mutations,
    getters
}