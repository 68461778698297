import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";

const getDefaultState = () => {
  return {
    availableBrandVoicesNum: 0,
    maxBrandVoicesNum: 0,
    brandVoices: [],
    brandVoicesPreset: [],
    defaultBrandVoice: null,
    activeBrandVoices: [],
    analizedText: "",
  };
};

const moduleBrandVoices = {
  state: getDefaultState(),
  getters: {
    getAvailableBrandVoicesNum(state) {
      return state.availableBrandVoicesNum;
    },
    getMaxBrandVoicesNum(state) {
      return state.maxBrandVoicesNum;
    },
    getBrandVoices(state) {
      return state.brandVoices;
    },
    getDefaultBrandVoice(state) {
      return state.defaultBrandVoice;
    },
    getActiveBrandVoices(state) {
      return [...state.activeBrandVoices, ...state.brandVoicesPreset];
    },
    getAnalizedText(state) {
      return state.analizedText;
    },
    canCreateBrandVoice(state, getters) {
      return (
        getters.getUserRole != 'user' &&
        getters.getMaxBrandVoicesNum > 0 &&
        getters.getAvailableBrandVoicesNum > 0
      );
    },
  },
  mutations: {
    clearModuleBrandVoices(state) {
      Object.assign(state, getDefaultState());
    },
    setAvailableBrandVoicesNum(state, availableBrandVoicesNum) {
      state.availableBrandVoicesNum = availableBrandVoicesNum;
    },
    setMaxBrandVoicesNum(state, maxBrandVoicesNum) {
      state.maxBrandVoicesNum = maxBrandVoicesNum;
    },
    setBrandVoices(state, brandVoices) {
      state.brandVoices = brandVoices.map((bv) => ({
          ...bv,
          active: Boolean(bv.active),
          default: Boolean(bv.default)
        })
      );
    },
    setBrandVoicesPreset(state, brandVoices) {
      state.brandVoicesPreset = brandVoices;
    },
    setDefaultBrandVoice(state, defaultBrandVoice) {
      state.defaultBrandVoice = defaultBrandVoice;
    },
    setActiveBrandVoices(state, activeBrandVoices) {
      state.activeBrandVoices = activeBrandVoices;
    },
    setAnalizedText(state, analizedText) {
      state.analizedText = analizedText;
    },
  },
  actions: {
    /**
     * Fetch brand voices data from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchBrandVoices({ getters, commit }, workspace_id) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
        workspace_id: workspace_id ?? null
      };

      const response = await axios.get("features/brand-voice/show", {
        params: params,
      });
      const availableBrandVoicesNum =
        response.data.brand_voices_limit.available_brand_voices;
      const maxBrandVoicesNum =
        response.data.brand_voices_limit.max_brand_voices;
      const brandVoices = response.data.brand_voices_list;
      let brandVoicesPreset = response.data.brand_voices_preset;
      for (let i = 0; i < brandVoicesPreset.length; i++) {
        brandVoicesPreset[i].name = `tov.${brandVoicesPreset[i].name}` 
        
      }
      const defaultBrandVoice = brandVoices.find((el) => el.default);
      const activeBrandVoices = brandVoices.filter((el) => el.active);
      console.log(response);
      brandVoices.sort((a, b) => a.name.localeCompare(b.name));

      // Update Vuex store with the retrieved data
      commit("setAvailableBrandVoicesNum", availableBrandVoicesNum);
      commit("setMaxBrandVoicesNum", maxBrandVoicesNum);
      commit("setBrandVoices", brandVoices);
      commit("setBrandVoicesPreset", brandVoicesPreset);
      commit("setActiveBrandVoices", activeBrandVoices);
      /* if (defaultBrandVoice) {
        commit("setDefaultBrandVoice", defaultBrandVoice);
      } */
      commit("setDefaultBrandVoice", defaultBrandVoice);
    },

    /**
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - Parameters for creating the user seat.
     * @param {string} params.text - The text to be analyzed.
     */
    async analizeText({ getters, commit }, params) {
      const user = getters.getUser;
      if (params instanceof FormData) {
        params.append("user_id", user.id);
      } else {
        params.user_id = user.id;
      }

      const response = await axios.post("features/brand-voice/create", params);

      // Update Vuex store with the retrieved data
      const analizedText = response.data.response;
      commit("setAnalizedText", analizedText);
    },

    /**
     * Create a new user seat and update Vuex store.
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - The parameters for creating the brand voice.
     * @param {string} params.name - The name of the brand voice.
     * @param {string} params.guidelines - The guidelines for the brand voice.
     * @param {boolean} params.default - Indicates if the brand voice is the default.
     */
    async createBrandVoice({ getters, commit, dispatch }, params) {
      return await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
        params.workspace_id = getters.selectedWorkspace?.id

        let response = await axios.put("features/brand-voice/create", params);

        // Refresh Vuex store with the updated data
        await dispatch("fetchBrandVoices", getters.selectedWorkspace?.id);
        commit("setAnalizedText", "");
        return response;
      });

      // Reset analizedText state

    },

    /**
     * Update an existing user seat and update Vuex store.
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - The parameters for creating the brand voice.
     * @param {number} params.id - The ID of the brand voice.
     * @param {string} params.name - The name of the brand voice.
     * @param {string} params.guidelines - The guidelines for the brand voice.
     * @param {boolean} params.default - Indicates if the brand voice is the default.
     */
    async updateBrandVoice({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.post("features/brand-voice/edit", params);

        // Refresh Vuex store with the updated data
        await dispatch("fetchBrandVoices");

        console.log('response', response)
        return response;
      });
    },

    /**
     * Reset the default brand voice and update Vuex store.
     *
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     */
    async resetDefault({ getters, dispatch }, workspace_id) {
      await apiHandler(async () => {
        const user = getters.getUser;
        const params = {
          user_id: user.id,
          workspace_id: workspace_id ?? null
        };

        const response = await axios.post(
          "features/brand-voice/reset_default",
          params
        );

        // Refresh Vuex store with the updated data
        await dispatch("fetchBrandVoices", workspace_id);

        return response;
      });
    },

    /**
     * Delete a brand voice and update Vuex store.
     *
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {number} id - The ID of the brand voice entry to be deleted.
     */
    async deleteBrandVoice({ getters, dispatch }, id) {
      await apiHandler(async () => {
        const user = getters.getUser;
        const params = {
          user_id: user.id,
          id,
        };

        const response = await axios.delete("features/brand-voice/delete", {
          params: params,
        });

        // Refresh Vuex store with the updated data
        await dispatch("fetchBrandVoices");

        return response;
      });
    },
  },
};

export default moduleBrandVoices;
