import axios from "../axios";

export { comprehend };

const comprehend = async (text) => {
  if (!text instanceof String) {
    throw new Error(
      'Comprehend Language: "text" parameter is not a string!'
    );
  }
  if (text.length <= 5) {
    throw new Error(
      'Comprehend Language: "text" parameter has 5 or less characters!'
    );
  }
  try {
    const response = await axios.get("/services/detect-language", {
      params: {
        string: text.substring(0, 400)
      }
    });
    return response.data.language;
  } catch (e) {
    console.error("Comprehend Language: error while detecting language, falling back to default", e);
    return 'en';
  }
};
