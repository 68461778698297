import axios from "axios";
import config from "./config";
import store from '@/store/store';

const instance = axios.create({
  baseURL: config.baseURL
});

const backendLanguage = store.getters.getSelectedLanguage === 'br' ? 'pt-BR' : store.getters.getSelectedLanguage;

instance.interceptors.request.use(function (config) {
  let bearerToken = store.getters.getToken ?? null;
  config.headers.Authorization = (bearerToken) ? `Bearer ` + bearerToken : '';

  // if (config.method !== "get") {
  // }

  if (config.data) {
    if (config.data instanceof FormData) {
      config.data.append('source', 'saas');
      config.data.append('user_language', backendLanguage);
    } else {
      config.data.source = "saas";
      config.data.user_language = backendLanguage;
    }
  }

  if (config.params) {
    config.params.source = "saas";
    config.params.user_language = backendLanguage;
  }

  return config;
});

export default instance;
