import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";

const getDefaultState = () => {
  return {
    briefings: [],
    analizedText: "",
  };
};

const moduleBriefings = {
  state: getDefaultState(),
  getters: {
    getBriefings(state) {
      return state.briefings;
    },
    createBriefing(state) {
      return state.analizedText;
    },
  },
  mutations: {
    setBriefings(state, briefings) {
      state.briefings = briefings
      // .map((bv) => ({
      //     ...bv,
      //     active: Boolean(bv.active),
      //     default: Boolean(bv.default)
      //   })
      // );
    },
    setCreateNewBriefing(state, analizedText) {
      state.analizedText = analizedText;
    },
  },
  actions: {
    /**
     * Fetch briefings data from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchBriefings({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
      };

      const response = await axios.get("/tools/show_user_guidelines", {
        params: params,
      });
      const briefings = response.data.result;
      commit("setBriefings", briefings);
    },

    /**
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - Parameters for creating the user seat.
     * @param {string} params.text - The text to be analyzed.
     */
    async createNewBriefing({ getters, commit,dispatch }, params) {
      const user = getters.getUser;
      if (params instanceof FormData) {
        params.append("user_id", user.id);
      } else {
        params.user_id = user.id;
      }

      const response = await axios.put("tools/create_user_guideline", params);

      // Update Vuex store with the retrieved data
      const briefing = response.data.response;
      await dispatch("fetchBriefings");
      commit("setCreateNewBriefing", briefing);
      return response
    },

    /**
     * Create a new user seat and update Vuex store.
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - The parameters for creating the brand voice.
     * @param {string} params.name - The name of the brand voice.
     * @param {string} params.guidelines - The guidelines for the brand voice.
     * @param {boolean} params.default - Indicates if the brand voice is the default.
     */
    async createBriefing({ getters, commit, dispatch }, params) {
      return await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        let response = await axios.put("tools/create_user_guideline", params);
        // Refresh Vuex store with the updated data
        commit("setCreateNewBriefing", "");
        return response;
      });

      // Reset analizedText state

    },

    /**
     * Update an existing user seat and update Vuex store.
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - The parameters for creating the brand voice.
     * @param {number} params.id - The ID of the brand voice.
     * @param {string} params.name - The name of the brand voice.
     * @param {string} params.guidelines - The guidelines for the brand voice.
     * @param {boolean} params.default - Indicates if the brand voice is the default.
     */
    async updateBriefing({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.post("tools/edit_user_guideline", params);

        // Refresh Vuex store with the updated data
        await dispatch("fetchBriefings");

        return response;
      });
    },


    /**
     * Delete a brand voice and update Vuex store.
     *
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {number} id - The ID of the brand voice entry to be deleted.
     */
    async deleteBriefing({ getters, dispatch }, id) {
      await apiHandler(async () => {
        const user = getters.getUser;
        const params = {
          user_id: user.id,
          guideline_id: id,
        };

        const response = await axios.delete("tools/delete_user_guideline", {
          params: params,
        });

        // Refresh Vuex store with the updated data
        await dispatch("fetchBriefings");

        return response;
      });
    },
  },
};

export default moduleBriefings;
