import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced:true,
    state(){
        return {
            changePlanDate: null,
            stripeClientSecret: null,
            defaultPaymentMethod: null,
            billingInfo: null,
            hasBillingInfo: null,
            discounts: {
                discount: 0,
                is_percentage: false,
                ai_tool_limit_uses: 0,
                coupon_ai_credits: 0,
                coupon_human_credits: 0
            },
            couponCode: null,
            checkoutPlan: null,
            stripeElements:null,
            paymentElement:null,
            paymentForm:null,
            formIsValid:false,
            isPaymentMethodChanged:false,
            gtmUserInfo: null
        };
    },
    actions,
    mutations,
    getters
}
