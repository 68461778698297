<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <div class="main-panel main-panel--auth" :data="sidebarBackground">
      <dashboard-navbar-auth></dashboard-navbar-auth>
      <router-view name="header"></router-view>
      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbarAuth from "./DashboardNavbarAuth.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { getDarkMode } from "../../services/localService";

export default {
  components: {
    DashboardNavbarAuth,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    iconFormat() {
      return "⇆";
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    // initScrollbar() {
    //   let docClasses = document.body.classList;
    //   let isWindows = navigator.platform.startsWith('Win');
    //   if (isWindows) {
    //     // if we are on windows OS we activate the perfectScrollbar function
    //     initScrollbar('sidebar');
    //     initScrollbar('main-panel');
    //     initScrollbar('sidebar-wrapper');

    //     docClasses.add('perfect-scrollbar-on');
    //   } else {
    //     docClasses.add('perfect-scrollbar-off');
    //   }
    // },
    initDarkMode() {
      let docClasses = document.body.classList;
      if (getDarkMode()) {
        docClasses.remove("white-content");
      } else {
        docClasses.add("white-content");
      }
    },
  },
  mounted() {
    // this.initScrollbar();
    this.initDarkMode();
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
