<template>
  <div class="credit-cell">
    <div class="base">
      <span class="wallet-label">{{ title }}</span>
      <span
        :id="title"
        :class="kind !== 'human' ? 'wallet-value' : 'wallet-value wallet-human-value'"
        >{{ labelCredits }}</span
      >
    </div>
    <div class="extended" v-if="paymentUrl !== ''">
      <RouterLink :to="paymentUrl" class="recharge-btn">
        <img src="/img/sidebar/icon1.png" class="recharge-img" alt="" />
        Recharge
      </RouterLink>
    </div>
  </div>
</template>
<script>
export default {
  name: "SidebarWalletCell",
  props: {
    title: {
      type: String,
      default: "",
      description: "section title",
    },

    credits: {
      type: [String, Number],
    },
    kind: {
      type: String,
      default: "",
      validator: (value) => {
        let acceptedValues = ["", "human"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    paymentUrl: {
      type: String,
      default: "",
      description: "the url of payment page",
    },
  },
  computed: {
    labelCredits() {
      if (this.credits == "unlimited") {
        return "Unlimited";
      }
      return this.credits;
    },
  },
};
</script>
<style>
.credit-cell {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.wallet-label {
  color: #a4aeb7;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
}
.sidebar:hover .base {
  align-items: flex-start;
}
.wallet-value {
  color: #fab322;
  font-family: Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  width: auto;
  display: flex;
  align-items: center;
}
.sidebar:hover .wallet-value {
  font-size: 16px;
  align-items: unset;
  width: 100%;
}
.wallet-value.wallet-human-value {
  color: #51a3a3;
}
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.extended {
  display: flex;
}
.sidebar:hover .extended {
  display: flex;
}
.recharge-btn {
  margin-top: 5px;
  flex-shrink: 0;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-family: Lato;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border-radius: 8px;
  border: 1px dashed rgba(154, 154, 255, 0.7);
  padding: 5px;
  max-height: 26px;
}
.recharge-btn:hover {
  color: #cddefe !important;
}
.sidebar:hover .wallet-label::after {
  content: "credits";
  margin-left: 5px;
}
.divider {
  height: 1px;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;
}

.sidebar:hover .credit-cell {
  justify-content: space-between;
}

@media (min-width: 992px) {
  .extended {
    display: none;
  }
  .credit-cell {
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .wallet-label::after {
    content: "credits";
    margin-left: 5px;
  }
  .wallet-value {
    font-size: 16px;
  }
}
</style>
