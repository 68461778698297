<template>
    <div>
        <h4 class="font-weight-700 text-blue">{{ $t('downgrade.' + userLimits?.plan_type + '.actual_plan_include') }} {{
            userLimits.user_seats.limit }} {{ $t('downgrade.user_seats.' + (userLimits.user_seats.limit > 1 ? 'plural' :
                'singular')) }}</h4>
        <p class="my-6">{{ $t('downgrade.user_seats.keep') }}</p>
        <ValidationObserver v-slot="{ handleSubmit }">

            <form class="" @submit.prevent="handleSubmit(onSubmit)">
                <div class="d-flex flex-column">

                    <div v-for="userSeat in userLimits.user_seats.active" :key="userSeat.id">
                        <base-checkbox :checked="userSeat.role === 'master' ? true : false"
                            :disabled="userSeat.role === 'master' ? true : false"
                            @change.native="selectItem(userSeat.id)" :value="userSeat.id"
                            :id="`userSeat_${userSeat.id}`">{{ userSeat.user.email
                            }}</base-checkbox>
                    </div>
                </div>
            </form>
        </ValidationObserver>

    </div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);

export default {
    name: "DowngradeUserSeats",
    props: {
        userLimits: Object,
    },
    data() {
        return {
            form: {
                brand_voices: {
                    selected: [],
                    exceeded: this.userLimits.brand_voices.exceeded
                },
                user_seats: {
                    selected: [],
                    exceeded: this.userLimits.user_seats.exceeded
                },
                workspaces: {
                    selected: [],
                    exceeded: this.userLimits.workspaces.exceeded
                },
            }
        };
    },
    methods: {
        // onSubmit() {
        //     this.$emit('submit', { ...this.form });
        // },

        selectItem(id) {
            this.$emit('submit', { id }, 'userSeats');
            // const index = this.form.user_seats.selected.indexOf(id);
            // if (index !== -1) {
            //     this.form.user_seats.selected = this.form.user_seats.selected.filter((id, idx) => idx !== index)
            // } else {
            //     this.form.user_seats.selected.push(id);
            // }
        }
    },
    computed: {
        // isFilled() {
        //     return this.form.user_seats.selected.length <= this.userLimits.user_seats.limit

        // },
    },
    mounted() {
        //prefill user seats checkbox with admins
        let admins = this.userLimits.user_seats.active
            .filter((user_seat) => user_seat.role === 'master')
            .map((user_seat) => user_seat.id);
    },
    created() {
        this.form.user_seats.selected.push(...admins);

    }
};

</script>