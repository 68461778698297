import axios from "@/axios";
import router from "@/routes/router";
import { Message } from "element-ui";
import { apiHandler } from "@/util/errorHandling";

const getDefaultState = () => {
  return {
    availableSeatsNum: 0,
    maxSeatsNum: 0,
    userSeats: [],
  };
};

const moduleUser = {
  state: getDefaultState(),
  getters: {
    getAvailableSeatsNum(state) {
      return state.availableSeatsNum;
    },
    getMaxSeatsNum(state) {
      return state.maxSeatsNum;
    },
    getUserSeats(state) {
      return state.userSeats;
    },
  },
  mutations: {
    clearModuleUserSeats() {
      Object.assign(state, getDefaultState());
    },
    setAvailableSeatsNum(state, availableSeatsNum) {
      state.availableSeatsNum = availableSeatsNum;
    },
    setMaxSeatsNum(state, maxSeatsNum) {
      state.maxSeatsNum = maxSeatsNum;
    },
    setUserSeats(state, userSeats) {
      state.userSeats = userSeats;
    },
  },
  actions: {
    /**
     * Fetch user seats data from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchUserSeats({ getters, commit }) {
      
      const user = getters.getUser;
      const { activePlanPackage } = getters?.walletInfoObj; 
      const response = await axios.get("features/user-seats/show", {
        params: {
          user_id: user.id,
        },
      });

      let availableSeatsNum =1;
      let maxSeatsNum = 1;
      const userSeats = response?.data?.user_seats_list ?? [];
      if(activePlanPackage?.can_upsell_user_seat){
        maxSeatsNum = getters?.walletInfoObj?.userSeats?.max_seats
        availableSeatsNum = getters?.walletInfoObj?.userSeats?.available_seats
      }
      
      
      commit("setAvailableSeatsNum", availableSeatsNum);
      commit("setMaxSeatsNum", maxSeatsNum);
      commit("setUserSeats", userSeats);
    },

    /**
     * Create a new user seat on the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - Parameters for creating the user seat.
     * @param {string} params.email - The email associated with the new user seat.
     */
    async createUserSeat({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.put("features/user-seats/create", params);
        // Refresh Vuex store with the updated data
        dispatch("fetchUserSeats");

        return response;
      });
    },

    async updateUserSeat({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.post("features/user-seats/edit", params);
        // Refresh Vuex store with the updated data
        dispatch("fetchUserSeupdateUserSeatats");

        return response;
      });
    },

    /**
     * Resend the user seat link for the specified user.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} params - Parameters for resending the user seat link.
     * @param {string} params.email - The email of the user for whom to resend the seat link.
     * @param {string} params.id - The ID of the user for whom to resend the seat link.
     */
    async resendUserSeatLink({ getters }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        /** ADMIN => user_id, USER => id */
        const response = await axios.post(
          "features/user-seats/resend_link",
          params
        );

        return response;
      });
    },

    /**
     * Delete a user seat on the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {string} id - The ID of the user seat to delete.
     */
    async deleteUserSeat({ getters, dispatch }, id) {
      await apiHandler(async () => {
        const user = getters.getUser;

        const response = await axios.delete("features/user-seats/delete", {
          params: {
            user_id: user.id,
            id,
          },
        });

        // Refresh Vuex store with the updated data
        dispatch("fetchUserSeats");

        return response;
      });
    },
  },
};

export default moduleUser;
