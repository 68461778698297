import { comprehend } from '@/util/comprehend';

const ComprehendPlugin = {
  install(Vue) {
    // Add the comprehend function to Vue prototype
    Vue.prototype.$comprehend = (string) => comprehend(string);
  },
};

export default ComprehendPlugin;
