export { toSnakeCase, capitalizeFirstLetter, slugToComponentName, replaceDiv };

const toSnakeCase = (string) => {
  const words = string.split(/\s+/);
  return words.map((word) => word.toLowerCase()).join("_");
};

const capitalizeFirstLetter = (string) => {
  console.log(string);
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const slugToComponentName = (slug) => {
  // Convert the slug to camel case
  const camelCaseName = slug
    .split("-")
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("")
    .split("_")
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");

  // Convert the first character to uppercase
  return camelCaseName.charAt(0).toUpperCase() + camelCaseName.slice(1);
};

const replaceDiv = (string) => {
  let s1 = string.replace('<div>', '<p>');
  let s2 = s1.replace('</div>', '</p>');
  return s2;
};

