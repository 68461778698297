import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";

const getDefaultState = () => {
  return {
    linkBuilderKeyword: null,
    linkBuilderKeywords: [],
    totalLinkBuilderKeywordsNum: 0,
  };
};

const moduleKeywordBuilder = {
  state: getDefaultState(),
  getters: {
    getLinkBuilderKeyword(state) {
      return state.linkBuilderKeyword;
    },
    getLinkBuilderKeywords(state) {
      return state.linkBuilderKeywords;
    },
    getTotalLinkBuilderKeywordsNum(state) {
      return state.totalLinkBuilderKeywordsNum;
    },
  },
  mutations: {
    clearModuleLinkBuilderKeyword(state) {
      Object.assign(state, getDefaultState());
    },
    setLinkBuilderKeyword(state, linkBuilderKeyword) {
      state.linkBuilderKeyword = linkBuilderKeyword;
    },
    setLinkBuilderKeywords(state, linkBuilderKeywords) {
      state.linkBuilderKeywords = linkBuilderKeywords;
    },
    setTotalLinkBuilderKeywordsNum(state, totalLinkBuilderKeywordsNum) {
      state.totalLinkBuilderKeywordsNum = totalLinkBuilderKeywordsNum;
    },
  },
  actions: {

    async createLinkBuilderKeyword({ getters, dispatch }, params) {
      return await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
        params.source = 'saas';

        const formData = new FormData();
        for (let key in params) {

          if (params.hasOwnProperty(key)) {
            let value = params[key];
            formData.append(key, value);

            /* if (Array.isArray(value)) {
              value.forEach((item) => formData.append(key+'[]', item))
            }
            else {
              // Parse boolean to integer
              if (typeof value === 'boolean') {
                value = value ? 1 : 0;
              }
            } */
          }
        }

        let response = await axios.post("tools/link_builder", formData);

        // Refresh Vuex store with the updated data
        await dispatch("fetchLinkBuilderKeywords");
        return response;
      });

    },


    async fetchLinkBuilderKeywords({ getters, commit }, params = {}) {
      // Get the currently authenticated user
      const user = getters.getUser;

      if (user) {
        const queryParams = {
          user_id: user.id,
          ...params,
        };

        // Fetch keywords based on the specified keyword type
        let response;
        response = await axios.get("tools/link_builder", {
          params: queryParams,
        });

        if (response) {
          const totalLinkBuilderKeywordsNum = response.data.recordsTotal;
          const linkBuilderKeywords = response.data.links;

          // Update Vuex store with fetched keywords and total count
          commit("setLinkBuilderKeywords", linkBuilderKeywords);
          commit("setTotalLinkBuilderKeywordsNum", totalLinkBuilderKeywordsNum);

          return;
        }
      }

      throw new Error("Failed to fetch keywords");
    },

    async updateLinkBuilderKeyword({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.patch("tools/link_builder", params);
        await dispatch("fetchLinkBuilderKeywords");
        return response;
      });
    },

    async deleteLinkBuilderKeyword({ getters, dispatch }, id) {
      await apiHandler(async () => {
        const user = getters.getUser;
        const params = {
          user_id: user.id,
          id: id,
        };

        const response = await axios.delete("tools/link_builder", {
          params: params,
        });

        // Refresh Vuex store with the updated data
        await dispatch("fetchLinkBuilderKeywords");

        return response;
      });
    },


    async anchorLinkingKeyword({ getters }, params) {
        const user = getters.getUser;
        params.user_id = user.id;
        const response = await axios.post("tools/link_builder/anchor_linking_keywords", params);
        return response.data.response;
    },

  },
};

export default moduleKeywordBuilder;
