import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced:true,
    state(){
        return {
            cart: [],
            cartType: null,
            pricePer50AICredits: 0.30,
            prorationAmount:0
        };
    },
    actions,
    mutations,
    getters
}