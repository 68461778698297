<template>
  <base-dropdown
    tag="li"
    :menu-on-right="!$rtl.isRTL"
    title-tag="a"
    class="nav-item pr-0"
    title-classes="nav-link"
    menu-classes="dropdown-languages"
  >
    <template slot="title">
      <div class="nav-item-selected d-flex">
        <span>{{ selectedLanguage.toUpperCase() }}</span>
        <i class="fa-regular fa-angle-down fs-12"></i>
      </div>
    </template>

    <li
      v-for="language in filteredLanguages"
      :key="language"
      class="nav-item dropdown-item nav-option-list"
      @click="updateSelectedLanguage(language)"
    >
      <span>{{ language.toUpperCase() }}</span>
    </li>
  </base-dropdown>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      websiteLanguages: "getWebsiteLanguages",
      selectedLanguage: "getSelectedLanguage",
    }),
    filteredLanguages() {
      const languages = [...this.websiteLanguages];
      return languages.filter((el) => el != this.selectedLanguage).sort();
    },
  },
  methods: {
    updateSelectedLanguage(selectedLanguage) {
      this.$store.dispatch("updateSelectedLanguage", {
        selectedLanguage,
        i18n: this.$i18n,
      });
    },

    getLanguageFlag(language) {
      if (language === "en") {
        return `/img/flags/EN-US-UK-flag.png`;
      }

      return `/img/flags/${language.toUpperCase()}-flag.png`;
    },
  },
};
</script>

<style lang="scss">
.nav-item-selected {
  display: flex;
  align-items: center;
  gap: 3px;
}

.dropdown-languages {
  min-width: 3.5rem !important;
  padding: 10px 7px;

  .nav-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
