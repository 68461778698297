export default {
    setStep(state, step){
        console.log('set step', step)
        state.step = step;
    },
    setCustomerType(state, customerType){
        state.customerType = customerType
    },
    setAppLanguages(state, appLanguages) {
        state.appLanguages = appLanguages;
    },
    setCurrentLangKey(state, currentLangKey) {
        state.currentLangKey = currentLangKey;
    },
    setUser(state, user) {
        state.user = user;
    },
    setFreeTrial(state, freeTrial) {
        state.freeTrial = freeTrial;
    },
    setEmailMatch(state, emailMatch){
        state.emailMatch = emailMatch
    },
    setUserEmail(state, userEmail){
        state.userEmail = userEmail
    },
    setConfirmEmail(state, confirmEmail){
        state.confirmEmail = confirmEmail
    },
    setValid(state, valid){
        state.valid = valid
    },
    setForm(state, form){
        state.form = form
    },
    resetForm(state) {
        state.form = {}
    },
    setFreeTrialFinalAgreement(state, freeTrialFinalAgreement){
        state.freeTrialFinalAgreement = freeTrialFinalAgreement
    }
}