<template>
  <el-dialog
    :title="
      !completeProfile
        ? $t('auth.survey_modal.welcome_title') + ' ' + fullnameOrEmail
        : $t('dashboard.thanks_for_purchase') + ' ' + fullnameOrEmail
    "
    class="dialog-xl survey_modal"
    :visible.sync="showForm"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <span>{{ $t("auth.survey_modal.welcome_desc") }}</span>

    <ValidationObserver v-slot="{ handleSubmit, valid }">
      <form class="" @submit.prevent="handleSubmit(onSubmit)">
        <div class="mt-3">
          <div v-if="completeProfile" class="">
            <label v-text="$t('placeholder.newPassword')"></label>
            <ValidationProvider
              name="Password"
              :rules="completeProfile ? 'required|password' : ''"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                :error="errors[0]"
                class="free-trial-input"
                :type="showPassword ? 'text' : 'password'"
                minlength="8"
                :placeholder="$t('placeholder.newPassword')"
                v-model="form.password"
                :addonRightIcon="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                @handleClickAddonRightWithClick="showHidePassword"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              >
              </base-input>
            </ValidationProvider>
          </div>
          <div class="tools_options">
            <div>
              <label>{{ $t("auth.survey_modal.role_label") }}</label>
            </div>
            <ValidationProvider rules="required">
              <el-select
                :popper-append-to-body="false"
                class="select-primary"
                size="medium"
                :placeholder="$t('auth.job_roles_placeholder')"
                @change="setSelectedJobRole"
                v-model="form.jobRole"
              >
                <el-option
                  v-for="jobRole in jobRoles"
                  class="select-primary"
                  :value="jobRole.id"
                  :label="$t(jobRole.name_trans_id)"
                  :key="jobRole.id"
                >
                </el-option>
              </el-select>
            </ValidationProvider>
          </div>
          <div v-if="showOtherJobRoleSelect">
            <ValidationProvider :rules="showOtherJobRoleSelect ? 'required' : ''">
              <BaseInput
                type="text"
                :placeholder="$t('auth.survey_modal.write_here_jobrole')"
                v-model="form.otherJobRole"
              >
              </BaseInput>
            </ValidationProvider>
          </div>

          <div class="tools_options">
            <div>
              <label>{{ $t("auth.survey_modal.industry_label") }}</label>
            </div>
            <ValidationProvider rules="required">
              <el-select
                :popper-append-to-body="false"
                class="select-primary"
                size="medium"
                :placeholder="$t('auth.billing_info.industry_placeholder')"
                @change="setSelectedIndustry"
                v-model="form.industry"
              >
                <el-option
                  v-for="industry in industryOptions"
                  class="select-primary"
                  :value="industry.id"
                  :label="$t(industry.trans_key)"
                  :key="industry.id"
                >
                </el-option>
              </el-select>
            </ValidationProvider>
          </div>
          <div v-if="showOtherIndustrySelect">
            <ValidationProvider :rules="showOtherIndustrySelect ? 'required' : ''">
              <BaseInput
                type="text"
                :placeholder="$t('auth.survey_modal.write_here_industry')"
                v-model="form.otherIndustry"
              >
              </BaseInput>
            </ValidationProvider>
          </div>
          <div class="tools_options">
            <div>
              <label>{{ $t("auth.survey_modal.company_size_label") }}</label>
            </div>
            <ValidationProvider rules="required">
              <el-select
                :popper-append-to-body="false"
                class="select-primary"
                size="medium"
                :placeholder="$t('auth.survey_modal.general_placeholder')"
                v-model="form.companySize"
              >
                <el-option
                  v-for="company in companyOptions"
                  class="select-primary"
                  :value="company.id"
                  :label="$t(company.trans_key)"
                  :key="company.id"
                >
                </el-option>
              </el-select>
            </ValidationProvider>
          </div>

          <div class="art-f-col">
            <label for="contentType">
              {{ $t("auth.survey_modal.which_content_selection.label") }}
            </label>
            <div class="survey-small-box-wrapper">
              <div
                class="survey-small-box"
                v-for="option in contentTypeOptions"
                :key="option.id"
                @click.prevent="setSelectedContentType(option.id)"
                :class="{
                  'survey-small-box-active': form.contentType == option.id,
                }"
              >
                <img :src="`/img/survey/${option.icon}.svg`" alt="" />
                <p>{{ $t(option.trans_key) }}</p>
              </div>
            </div>
          </div>

          <div class="tools_options">
            <div>
              <label>{{ $t("auth.survey_modal.how_proficient_label") }}</label>
            </div>
            <ValidationProvider rules="required">
              <el-select
                :popper-append-to-body="false"
                class="select-primary"
                size="medium"
                :placeholder="$t('auth.survey_modal.general_placeholder')"
                v-model="form.howProficientAre"
              >
                <el-option
                  v-for="(howProficientAre, index) in howProficientAreOptions"
                  class="select-primary"
                  :value="howProficientAre.trans_key"
                  :label="$t(howProficientAre.trans_key)"
                  :key="index"
                >
                </el-option>
              </el-select>
            </ValidationProvider>
          </div>

          <div v-if="completeProfile" class="col-12 mt-3">
            <div class="d-flex">
              <el-switch class="" v-model="form.agreement"></el-switch>
              <label class="ml-3 text-grey"
                >By continuing, I agree with your Terms and Conditions and Privacy
                Policy</label
              >
            </div>
          </div>
          <SubmitButton text="Save" :isDisabled="!isValidInputs" />
        </div>
      </form>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { Dialog, Select, Option, Switch } from "element-ui";
import { mapGetters } from "vuex";
import axios from "@/axios";
import { BaseInput } from "src/components/index";
import { isValidPassword } from "@/util/customValidators";
import SubmitButton from "src/components/Buttons/SubmitButton.vue";

extend("email", email);
extend("required", required);

extend("password", {
  message:
    "Password is not valid. Must be min 8 chars, at least one lowercase, one upper case and one special char",
  validate: (value) => {
    return isValidPassword(value);
  },
});

export default {
  components: {
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    BaseInput,
    [Switch.name]: Switch,
    SubmitButton,
  },
  data() {
    return {
      form: {
        jobRole: null,
        otherJobRole: null,
        otherIndustry: null,
        industry: null,
        companySize: null,
        contentType: null,
        howProficientAre: null,
        password: null,
        agreement: false,
        source: "saas",
      },
      showForm: true,
      jobRoles: null,
      industryOptions: null,
      contentTypeOptions: null,
      companyOptions: null,
      howProficientAreOptions: [
        { trans_key: "auth.survey_modal.how_proficient_select.none" },
        { trans_key: "auth.survey_modal.how_proficient_select.some" },
        { trans_key: "auth.survey_modal.how_proficient_select.lots" },
      ],
      showPassword: false,
      showOtherJobRoleSelect: false,
      showOtherIndustrySelect: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),

    fullnameOrEmail() {
      return this.user?.name ?? this.user?.email;
    },

    isValidInputs() {
      if (this.completeProfile) {
        return (
          this.form.agreement &&
          this.form.contentType &&
          this.form.jobRole &&
          this.form.industry &&
          this.form.howProficientAre &&
          this.form.password &&
          this.form.companySize
        );
      }
      return (
        this.form.contentType &&
        this.form.jobRole &&
        this.form.industry &&
        this.form.howProficientAre &&
        this.form.companySize
      );
    },
  },
  props: {
    completeProfile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit", { ...this.form });
    },

    async getSurveyOptions() {
      await axios
        .get(`data/survey-options`, { user_id: this.user.id })
        .then(({ data }) => {
          console.log(data);
          this.jobRoles = data.jobRoles;
          this.industryOptions = data.surveyIndustryOptions.map((option) => ({
            id: option.id,
            name: this.$t(option.trans_key),
            trans_key: option.trans_key,
          }));
          this.contentTypeOptions = data.surveyContentTypeOptions;
          this.companyOptions = data.surveyCompanyOptions.map((option) => ({
            id: option.id,
            name: this.$t(option.trans_key),
            trans_key: option.trans_key,
          }));
          this.howProficientAreOptions = this.howProficientAreOptions.map((option) => ({
            trans_key: option.trans_key,
            name: this.$t(option.trans_key),
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setSelectedContentType(value) {
      this.form.contentType = value;
    },

    setSelectedJobRole() {
      if (this.form.jobRole == 12) {
        this.showOtherJobRoleSelect = true;
      } else {
        this.showOtherJobRoleSelect = false;
      }
    },

    setSelectedIndustry() {
      if (this.form.industry == 10) {
        this.showOtherIndustrySelect = true;
      } else {
        this.showOtherIndustrySelect = false;
      }
    },

    showHidePassword() {
      this.showPassword = !this.showPassword;
    },
  },
  created() {
    this.getSurveyOptions();
  },
};
</script>

<style lang="scss">
.survey_modal {
  .el-dialog {
    max-width: 685px !important;
    margin-top: 5vh !important;
  }
}
.survey_modal::after {
  display: none !important;
}

.survey-small-box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .survey-small-box {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #f3f2f0;
    background: #fff;
    width: 300px;
    height: 60px;
    margin-bottom: 15px;
    overflow: hidden;
    cursor: pointer;

    p {
      width: calc(100% - 50px);
      margin-left: 10px;
    }

    &:nth-child(odd) {
      margin-right: 12px;
    }

    &:hover {
      background: #004583;

      p {
        color: #fff !important;
      }

      img {
        filter: drop-shadow(0px 100px 0px #fff);
        transform: translateY(-100px);
      }
    }
  }

  .survey-small-box-active {
    background: #004583;

    p {
      color: #fff !important;
    }

    img {
      filter: drop-shadow(0px 100px 0px #fff);
      transform: translateY(-100px);
    }
  }
}
</style>
