<template>
  <router-link :to="paymentUrl" class="upg-btn">
    <img src="/img/sidebar/icon1.png" alt="" />
    <span class="s-h"> {{ $t('pricing.cards.upgrade_now') }} </span>
  </router-link>
</template>
<script>
export default {
  name: "SidebarUpgradeButton",
  props: {
    paymentUrl: {
      type: String,
      default: "",
      description: "the url of payment page",
    },
  },
};
</script>
<style>
.upg-btn {
  margin-top: 11px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  background: #32329b;
  color: #fff !important;
  text-transform: uppercase;
  border: 1px dashed #9a9aff;

  font-size: 12px;
  border-radius: 50%;
  font-weight: 700;
  width: 32px;
  height: 32px;
  line-height: 1;
  white-space: nowrap;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  /* flex-direction: column; */
}

.s-h {
  display: none;
}

.sidebar:hover .s-h {
  display: block;
}

.sidebar:hover .upg-btn {
  width: 100%;
  border-radius: 5px;
}

@media (max-width: 991px) {
  .upg-btn {
    width: 160px;
    border-radius: 5px;
  }

  .s-h {
    display: block;
  }
}
</style>
