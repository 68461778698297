<template>
  <el-dialog
    :visible.sync="showModal"
    width="35%"
    center
    :lock-scroll="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="el-dialog-payout"
  >
    <h4
      v-text="$t('auth.failed_payment_modal.confirm_payment_desc')"
      class="text-center mb-0"
    ></h4>
    <span slot="footer" class="dialog-footer">
      <div class="row mb-2">
        <div class="col-sm-12 text-center">
          <BaseButton
            class="sub-btn cancel-sub-btn mr-2"
            @click="cancelSubscriptionConfermed"
            v-text="$t('auth.failed_payment_modal.cancel_sub_btn')"
          />
          <BaseButton
            class="sub-btn"
            @click="openUrlInNewTab(getFailedPaymentUrl)"
            v-text="$t('auth.failed_payment_modal.confirm_payment_btn')"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <p
            class="text-blue"
            style="font-size: 12px"
            v-html="$t('auth.failed_payment_modal.confirm_payment_desc_small')"
          ></p>
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Dialog } from "element-ui";
import BaseButton from "../../../components/BaseButton.vue";
export default {
  name: "FailedPaymentModal",
  components: {
    [Dialog.name]: Dialog,
    BaseButton,
  },
  props: ["showModal"],
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    openUrlInNewTab(url) {
      window.open(url, "_blank");
    },

    ...mapActions({
      cancelMainSubscriptionAction: "payments/cancelMainSubscription",
    }),

    async cancelSubscriptionConfermed() {
      try {
        this.$preloader.fadeIn();
        const { data } = await this.cancelMainSubscriptionAction();
        this.$preloader.fadeOut();
        if (data.status === "success") {
          this.$emit("close");
          this.$store.dispatch("fetchWalletInfo");
          this.$router.push("dashboard");
          Message({
            type: data.status,
            message: data.message,
          });
        }
      } catch (error) {
        Message({
          type: "error",
          message: error.message,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["isFailedPaymentsProcessed", "getFailedPaymentUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cancel-sub-btn {
  background: linear-gradient(203deg, #eb9634 0%, #eb4034 100%) !important;
}
.el-dialog__wrapper:after {
  background-color: transparent !important;
  content: "" !important;
}
.sub-btn {
  width: 45% !important;
}
</style>
