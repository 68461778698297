import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler, apiCreateOrderHandler } from "@/util/errorHandling";
import { Message } from "element-ui";
import { replaceDiv } from '@/util/strings';
import { replace } from "lodash";

const getDefaultState = () => {
  return {
    order: null,
    orders: [],
    products: [],
    totalOrdersNum: 0,
    keywords: [],
    selectedKeywords: [],
    confermedKeywords: [],
    selectedKeywordsTopic: null,
    focusKeywords: [],
    confermedFocusKeywords: [],
    imagesCategory: false,
    numImages: 0,
    addConclusions: false,
    anchorLinks: [],
    savedImages: [],
    createOrderError: false,
    resetFields: false,
  };
};

const moduleOrders = {
  state: getDefaultState(),
  getters: {
    getOrder(state) {
      return state.order;
    },
    getOrders(state) {
      return state.orders;
    },
    getTotalOrdersNum(state) {
      return state.totalOrdersNum;
    },
    getProducts(state) {
      return state.products;
    },
    getKeywords(state) {
      return state.keywords;
    },
    selectedKeywords(state) {
      return state.selectedKeywords;
    },
    confermedKeywords(state) {
      return state.confermedKeywords;
    },
    selectedKeywordsTopic(state) {
      return state.selectedKeywordsTopic;
    },
    focusKeywords(state) {
      return state.focusKeywords;
    },
    confermedFocusKeywords(state) {
      return state.confermedFocusKeywords;
    },
    imagesCategory(state) {
      return state.imagesCategory;
    },
    numImages(state) {
      return state.numImages;
    },
    addConclusions(state) {
      return state.addConclusions;
    },
    anchorLinks(state) {
      return state.anchorLinks;
    },
    savedImages(state) {
      return state.savedImages;
    },
    getCreateOrderError(state) {
      return state.createOrderError
    },
    getResetFields(state) {
      return state.resetFields;
    }
  },
  mutations: {
    clearModuleOrders(state) {
      Object.assign(state, getDefaultState());
    },
    setOrder(state, order) {
      state.order = order;
      state.order.content = typeof order.content === 'string' ? replaceDiv(order.content) : order.content;

      if (order?.content?.blog_post_html) {
        state.order.content.blog_post_html = replaceDiv(order?.content?.blog_post_html);
      }
    },
    setOrderContent(state, content) {
      state.order.content = content;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setTotalOrdersNum(state, totalOrdersNum) {
      state.totalOrdersNum = totalOrdersNum;
    },
    setProducts(state, products) {
      state.products = products;
    },
    setOrderApiTitle(state, apiTitle) {
      if (state.order) {
        state.order.title = apiTitle;
      }
    },
    setBlogPostTitle(state, apiTitle) {
      if (state.order) {
        state.order.content.main_title = apiTitle;
      }
    },
    setOrderTitles(state, titles) {
      if (state.order) {
        state.order.titles = titles;
      }
    },
    setBlogPostOrderTitles(state, titles) {
      if (state.order) {
        state.order.content.paragraphs_headings = titles;
      }
    },
    setKeywords(state, keywords) {
      state.keywords.push(keywords);
    },
    setSelectedKeywords(state, selectedKeywords) {
      state.selectedKeywords = selectedKeywords;
    },
    setSelectedKeywordsTopic(state, selectedKeywordsTopic) {
      state.selectedKeywordsTopic = selectedKeywordsTopic;
    },
    setFocusKeywords(state, focusKeywords) {
      state.focusKeywords = focusKeywords
    },
    setConfermedKeywords(state, confermedKeywords) {
      state.confermedKeywords = confermedKeywords;
    },
    setConfermedFocusKeywords(state, confermedFocusKeywords) {
      state.confermedFocusKeywords = confermedFocusKeywords;
    },
    setImagesCategory(state, imagesCategory) {
      state.imagesCategory = imagesCategory;
    },
    setOrderCategory(state, category) {
      if (state.order) {
        state.order.category = category;
      }
    },
    setSavedImages(state, images) {
      if (state.order) {
        state.savedImages = images;
      }
    },
    setNumImages(state, numImages) {
      state.numImages = numImages;
    },
    setAddConclusions(state, addConclusions) {
      state.addConclusions = addConclusions;
    },
    setAnchorLinks(state, anchorLinks) {
      state.anchorLinks = anchorLinks;
    },
    setCreateOrderError(state, createOrderError) {
      state.createOrderError = createOrderError;
    },
    setResetFields(state, resetFields) {
      state.resetFields = resetFields;
    },
  },
  actions: {
    async fetchOrder(context, params) {
      if (params.id) {
        // Get the currently authenticated user
        const user = context.getters.getUser;

        if (user) {
          const queryParams = {
            user_id: user.id,
            order_detail_id: params.id,
            workspace_id: context.getters.selectedWorkspace?.id,
          };

          // Fetch orders based on the specified order type
          let response;
          switch (params.category ?? "text") {
            case "media":
              response = await axios.get("services/media/orders/show", {
                params: queryParams,
              });
              break;
            case "text":
            default:
              response = await axios.get("services/orders/show", {
                params: queryParams,
              });
          }

          const orders = response.data.data.orders;

          // If orders are retrieved, process and store the first order
          if (orders && orders[0]) {
            const order = orders[0];

            return context.commit("setOrder", order);
          }
        }
      }



      // Throw an error if the required parameters are not provided or if the order retrieval process fails
      throw new Error("Failed to fetch order");
    },


    async getOrderHtmlContent(context, params) {
      if (params.id) {
        // Get the currently authenticated user
        const user = context.getters.getUser;

        if (user) {
          const queryParams = {
            user_id: user.id,
            order_detail_id: params.id,
            workspace_id: context.getters.selectedWorkspace?.id,
            action: "retrieve-html",
          };

          let response = await axios.get("services/orders/show", {
            params: queryParams,
          });

          return response;
        }
      }

      throw new Error("Failed to fetch order");
    },

    async fetchOrders({ getters, commit }, params = {}) {
      // Get the currently authenticated user
      const user = getters.getUser;

      if (user) {
        const queryParams = {
          user_id: user.id,
          workspace_id: getters.selectedWorkspace?.id,
          ...params,
        };

        // Fetch orders based on the specified order type
        let response;
        switch (params.resultType ?? "text") {
          case "media":
            response = await axios.get("services/media/orders/show", {
              params: queryParams,
            });
            break;
          case "text":
          default:
            response = await axios.get("services/orders/show", {
              params: queryParams,
            });
        }

        if (response) {
          const totalOrdersNum = response.data.data.recordsTotal;
          const orders = response.data.data.orders;
          const folders = response.data.data.folders ?? [];

          // Update Vuex store with fetched orders and total count
          commit("setOrders", orders);
          commit("setTotalOrdersNum", totalOrdersNum);
          commit("setFilteredFolders", folders);

          return;
        }
      }

      throw new Error("Failed to fetch orders");
    },

    async fetchProducts(context) {
      const user = context.getters.getUser;

      if (user) {
        const queryParams = {
          user_id: user.id,
          workspace_id: context.getters.selectedWorkspace?.id,
          action: "product-list",
        };
        const response = await axios.get("services/orders/show", {
          params: queryParams,
        });

        if (response) {
          const products = Object.entries(response.data.data).map(
            ([key, name]) => ({
              key,
              name,
            })
          );

          return context.commit("setProducts", products);
        }
      }

      throw new Error("Failed to fetch products");
    },

    async fetchSeoKeywords({ getters, commit }, params) {
      const user = getters.getUser;
      params.user_id = user.id;
      //params.workspace_id = getters.selectedWorkspace?.id;

      const response = await axios.put("services/orders/create", params);

      const keywords = {
        keywords: params.keywords,
        language: params.language,
        results: response.data.result
      };

      commit("setKeywords", keywords);
    },

    async createOrder({ getters, dispatch}, params) {
      window.gtm.tool_order_requested(params.product_keys);
      return await apiCreateOrderHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
        params.workspace_id = getters.selectedWorkspace?.id ?? '';

        const category = params.category ?? "text";
        delete params.category;
        // Loop through the object properties and append them to FormData
        const formData = new FormData();
        for (let key in params) {
          if (params.hasOwnProperty(key)) {
            let value = params[key];

            if (Array.isArray(value)) {
              value.forEach((item) => formData.append(key + '[]', item))
            }
            else {
              // Parse boolean to integer
              if (typeof value === 'boolean') {
                value = value ? 1 : 0;
              }
              formData.append(key, value);
            }
          }
        }

        let response;
        switch (category) {
          case "media":
              response = await axios.post("services/media/orders/create", formData);
            break;
          case "text":
          default:
              response = await axios.post("services/orders/create", formData)
        }
        await dispatch("fetchWalletInfo");

        window.gtm.tool_order_completed({...response.data, ...params.product_keys});
        return response;
      },params);

    },

    async createOrderWithCategory({ getters }, params) {
      return await apiCreateOrderHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
        params.workspace_id = getters.selectedWorkspace?.id ?? '';

        const category = params.category ?? "text";
        // Loop through the object properties and append them to FormData
        const formData = new FormData();
        for (let key in params) {
          if (params.hasOwnProperty(key)) {
            let value = params[key];

            if (Array.isArray(value)) {
              value.forEach((item) => formData.append(key + '[]', item))
            }
            else {
              // Parse boolean to integer
              if (typeof value === 'boolean') {
                value = value ? 1 : 0;
              }
              formData.append(key, value);
            }
          }
        }

        let response;
        switch (category) {
          case "media":
            response = await axios.post("services/media/orders/create", formData);
            break;
          case "text":
          default:
            response = await axios.post("services/orders/create", formData)
        }
        return response;
      });
    },

    /**
     * Update an order to an existing folder or to root
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} params - The parameters for creating the folder.
     * @param {number} params.order_id - The ID of the order
     * @param {number} params.order_detail_id
     * @param {string} params.action - restore or trash.
     */
    async updateOrder({ getters }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.post("services/orders/update", params);

        return response;
      });
    },

    /**
     * Delete an order to an existing folder or to root
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} params - The parameters for creating the folder.
     * @param {number} orderId - The ID of the order
     */
    async deleteOrder({ getters }, params) {
      console.log('params', params);
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;



        const response = await axios.delete("services/orders/delete", {
          params
        });

        return response;
      });
    },

    async downloadOrder({ getters }, { orderDetailId, format, type }) {
      await apiHandler(async () => {
        const user = getters.getUser;

        const queryParams = {
          user_id: user.id,
          order_detail_id: orderDetailId,
        };

        if (format) {
          queryParams.format = format;
        }

        if (type) {
          queryParams.type = type;
        }

        const response = await axios.get("services/orders/download", {
          params: queryParams,
          responseType: "blob",
        });

        let filename = null;
        if (format === 'csv') {
          filename = orderDetailId + '.csv'
        } else {
          filename = response.request
          .getResponseHeader("Content-Disposition")
          .split("filename=")[1];
        }

        const blob = new Blob([response.data]);
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        link.remove();

        return response;
      });
    },

    async fetchHistoryConversation({ getters }) {
      const user = getters.getUser;
      const params = {
        "user_id": user.id,
        "workspace_id": getters.selectedWorkspace?.id ?? null
      }

      let response;
      response = await axios.get("services/orders/tools/aichat/list", {params});
      return response
    },

    async showOrderHistory({ getters },order_id) {
      const user = getters.getUser;
      const params = {
        "user_id" : user.id,
        "order_id" : order_id
      }

      params.user_id = user.id
      let response;
      response = await axios.get("/services/orders/order_history", {params});
      return response
    },

    async acceptHumanOrder({ getters }) {
      const user = getters.getUser;
      const params = {
        "user_id": user.id,
      }

      let response;
      response = await axios.get("orders/user-accepted-contents", params);
      return response
    },

    async showOrderComments({ getters },params) {
      const user = getters.getUser;
      params.user_id = user.id;
      let response;
      response = await axios.get("comments/show", {params});
      return response
    },

    async createComments({ getters },params) {
      const user = getters.getUser;
      params.user_id = user.id;

      // Loop through the object properties and append them to FormData
      const formData = new FormData();
      for (let key in params) {
        if (params.hasOwnProperty(key)) {
          let value = params[key];

          if (Array.isArray(value)) {
            value.forEach((item) => formData.append(key+'[]', item))
          }
          else {
            // Parse boolean to integer
            if (typeof value === 'boolean') {
              value = value ? 1 : 0;
            }
            formData.append(key, value);
          }
        }
      }

      let response;
      response = await axios.put("comments/create", params);
      return response
    },

    async editComments({ getters },params) {
      const user = getters.getUser;
      params.user_id = user.id;
      let response;
      response = await axios.post("comments/edit", params);
      return response
    },

    async deleteComments({ getters },params) {
      const user = getters.getUser;
      params.user_id = user.id;
      let response;
      response = await axios.delete("comments/delete", {params});
      return response
    },

    async showWorkspaceUsersListForComments({ getters },params) {
      const user = getters.getUser;
      params.user_id = user.id;
      let response;
      response = await axios.get("comments/show_comment_user", {params});
      return response
    },
  },
};

export default moduleOrders;
