<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-label': label,
      'has-icon': hasIcon,
    }"
  >
    <slot name="label">
      <label v-if="label" class="base-input__label">
        {{ label }} {{ required ? "*" : "" }}
        <span v-if="optional" class="optional">(Optional)</span>
        <el-tooltip v-if="tooltip" :content="tooltip" placement="top">
          <i class="fa-info-circle fa-solid ml-2"></i>
        </el-tooltip>
      </label>
    </slot>
    <div class="mb-0" :class="{ 'input-group': hasIcon }">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
      </slot>
      <slot>
        <input
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          class="form-control"
          :class="inputClasses"
          aria-describedby="addon-right addon-left"
        />
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text">
            <i
              :class="addonRightIcon"
              @click="handleClickAddonRightWithClick"
            ></i>
          </div>
        </span>
      </slot>
      <slot name="sendBtnAddonRight">
        <span v-if="sendBtnAddonRight" class="input-group-append">
          <div class="input-group-text" id="sendBtnAddonRight">
            <button type="submit" :class="sendBtnAddonRight">
              <i data-v-07302bdd="" class="fas fa-paper-plane"></i> Send
            </button>
          </div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: Boolean,
    label: {
      type: String,
      description: "Input label",
    },
    optional: {
      type: Boolean,
      description: "Optional label",
    },
    error: {
      type: String,
      description: "Input error",
      default: "",
    },
    tooltip: {
      type: String,
      description: "Informative Message",
      default: null,
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right",
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left",
    },
    inputClasses: {
      type: Array,
    },
    sendBtnAddonRight: {
      type: Object,
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      focused: false,
      touched: false,
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon || this.sendBtnAddonRight;
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonRightIcon != ""
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit("input", evt.target.value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    },
    handleClickAddonRightWithClick(evt) {
      this.$emit("handleClickAddonRightWithClick", evt.target.className);
    },
  },
};
</script>
<style scoped lang="scss">
.base-input__label {
  color: #fff;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 14px;
  margin-bottom: 0.5rem !important;
  display: inline-block;
}

#sendBtnAddonRight {
  padding: 5px 5px 5px 0;
  button {
    width: 85px;
  }
}

.fa-circle-xmark {
  cursor: pointer;
}
</style>
