<template>
  <el-dialog :title="userLimits.is_admin ? $t('downgrade.manage') : ''" class="el-dialog-payout"
    :visible.sync="showForm" :lock-scroll="true" :close-on-click-modal="false" :close-on-press-escape="false"
    :show-close="false">
    <div v-if="userLimits.is_admin" class="col-12">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="" @submit.prevent="handleSubmit(removeExtraLimits)">
          <div class="button_switch_panel-container d-flex">
            <button v-for="(limit, index) in userLimitExcedeed" :key="index" @click.prevent="switchTab = limit.tab"
              class="button_switch_panel" :class="switchTab == limit.tab ? 'button_switch_panel--selected' : ''
                ">
              {{ limit.text }}
            </button>
          </div>
          <DowngradeUserSeats v-show="switchTab == 'userSeats'" :userLimits="userLimits" @submit="selectItem" />
          <DowngradeBrandVoices v-show="switchTab == 'brandVoices'" :userLimits="userLimits" @submit="selectItem" />
          <DowngradeWorkspaces v-show="switchTab == 'workspaces'" :userLimits="userLimits" @submit="selectItem" />
          <base-button :class="['btn__filled', !isFilled ? 'btn__not_filled' : '']" :disable="!isFilled"
            native-type="submit" type="primary" class="btn-fill btn_submit_form w-25 ml-auto">
            {{ $t("generic.save") }}
          </base-button>
        </form>
      </ValidationObserver>
    </div>
    <div v-else>
      <div class="col-lg-12 text-center">
        <h4 class="">Non hai i privilegi di accesso</h4>
        <p class="" style="font-size: 14px">
          Contatta l'amminstratore per maggiori informazioni
        </p>
      </div>
      <div class="col-lg-12 mt-2 text-center">
        <base-button native-type="submit" type="primary" class="btn-fill btn_submit_form w-25 ml-auto">
          ESCI
        </base-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from "element-ui";
import { mapGetters } from "vuex";
import DowngradeUserSeats from "./DowngradeUserSeats.vue";
import DowngradeBrandVoices from "./DowngradeBrandVoices.vue";
import DowngradeWorkspaces from "./DowngradeWorkspaces.vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);

export default {
  name: "DowngradePlan",
  components: {
    [Dialog.name]: Dialog,
    DowngradeUserSeats,
    DowngradeBrandVoices,
    DowngradeWorkspaces,
  },
  props: {
    userLimits: Object,
  },
  data() {
    return {
      showForm: true,
      switchTab: "",
      form: {
        brand_voices: {
          selected: [],
          exceeded: this.userLimits.brand_voices.exceeded,
        },
        user_seats: {
          selected: [],
          exceeded: this.userLimits.user_seats.exceeded,
        },
        workspaces: {
          selected: [],
          exceeded: this.userLimits.workspaces.exceeded,
        },
      },
    };
  },
  methods: {
    selectItem(id, type) {

      console.log(id.id, type);
      if (type == "userSeats") {
        const index = this.form.user_seats.selected.indexOf(id.id);
        if (index !== -1) {
          this.form.user_seats.selected = this.form.user_seats.selected.filter(
            (id, idx) => idx !== index
          );
        } else {
          this.form.user_seats.selected.push(id.id);
        }
      } else if (type == "workSpaces") {
        const index = this.form.workspaces.selected.indexOf(id.id);
        if (index !== -1) {
          this.form.workspaces.selected = this.form.workspaces.selected.filter(
            (id, idx) => idx !== index
          );
        } else {
          this.form.workspaces.selected.push(id.id);
        }
      } else {
        const index = this.form.brand_voices.selected.indexOf(id.id);
        if (index !== -1) {
          this.form.brand_voices.selected =
            this.form.brand_voices.selected.filter((id, idx) => idx !== index);
        } else {
          this.form.brand_voices.selected.push(id.id);
        }
        console.log(this.userLimits.brand_voices.exceeded, this.form.brand_voices.selected.length, this.userLimits.brand_voices.limit);
      }

    },

    async removeExtraLimits() {
      this.$preloader.fadeIn();
      const response = await this.$store.dispatch(
        "removeExtraLimits",
        this.form
      );
      if (response) {
        //add setTimeout because the method called it's too fast
        setTimeout(() => {
          location.reload();
          this.$preloader.fadeOut();
        }, 1000);
      }
    },
  },
  computed: {
    userLimitExcedeed() {
      const limitExcedeed = [];
      if (this.userLimits.user_seats.exceeded) {
        this.form.user_seats.selected.push(this.userLimits.user_seats.active[0].id)
        limitExcedeed.push({
          tab: "userSeats",
          text: "Seats",
          selected: true,
        });
      }
      if (this.userLimits.workspaces.exceeded) {
        limitExcedeed.push({
          tab: "workspaces",
          text: "Workspaces",
          selected: false,
        });
      }
      if (this.userLimits.brand_voices.exceeded) {
        limitExcedeed.push({
          tab: "brandVoices",
          text: "Brandvoices",
          selected: false,
        });
      }
      limitExcedeed[0].selected = true;
      return limitExcedeed;
    },

    brandVoicesFilled() {
      return this.userLimits.brand_voices.exceeded
        ? this.form.brand_voices.selected.length ==
        this.userLimits.brand_voices.limit
        : true;
    },

    workSpacesFilled() {
      return this.userLimits.workspaces.exceeded
        ? this.form.workspaces.selected.length ==
        this.userLimits.workspaces.limit
        : true;
    },

    userSeatsFilled() {
      return this.userLimits.user_seats.exceeded
        ? this.form.user_seats.selected.length ==
        this.userLimits.user_seats.limit
        : true;
    },

    isFilled() {
      return (
        this.brandVoicesFilled && this.userSeatsFilled && this.workSpacesFilled
      );
    },
  },
  watch: {
    userLimitExcedeed(newValue) {
      console.log(newValue);
      this.switchTab = newValue[0].tab;
    },
  },
  created() {
    this.switchTab = this.userLimitExcedeed[0]?.tab ?? "";
  },
};
</script>

<style lang="scss" scoped>
#downgradePlanModal {
  .modal-dialog {
    min-width: 535px;
  }

  @media screen and (max-width: 768px) {
    .modal-dialog {
      min-width: auto;
    }

    .modal-body {
      padding: 1.5rem !important;
    }
  }

  .modal-content {
    box-shadow: 4px 4px 29px 0px #00000040;

    &.modal-admin {
      min-height: 750px;
    }
  }

  h1 {
    font-weight: 400;
  }

  .description {
    font-size: 14px;
    color: #004583;

    p {
      font-size: 14px;
      color: #004583;
    }

    ul {
      list-style-type: unset;
      padding-left: 1.5rem;
    }

    li {
      list-style-type: unset;
    }
  }

  .tab-head {
    ul {
      overflow: initial;
    }

    li {
      width: 32% !important;
    }

    li:not(:last-child) {
      margin-right: 2%;
    }

    li:last-child {
      margin-right: 0;
    }

    .nav-link {
      min-width: unset;
      max-width: 100%;
      width: 100%;
      padding: 6px 10px;
      border: 1px solid #004583;
      background: white;
      color: #004583;
    }

    .nav-link.active {
      color: #fff !important;
      background-color: #004583 !important;
    }

    @media screen and (max-width: 768px) {
      .nav-link {
        font-size: 11px;
        min-width: fit-content;
      }
    }
  }

  #pills-tabContent {
    min-height: 360px;
  }

  .tab-pane .overflow_container {
    height: 200px;
    max-height: 200px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      transition: 0.3s;
    }

    &::-webkit-scrollbar-thumb,
    &:hover::-webkit-scrollbar-thumb {
      background: #c5c7cb;
    }

    &::-webkit-scrollbar-track {
      background: #edeff2;
    }

    .art-f-col label {
      font-size: 12px;
      font-weight: 400;
      color: #004583;
    }

    .user-checkbox.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    input[type="checkbox"]:checked:before {
      color: #004583 !important;
      background-color: white;
    }

    input[type="checkbox"]:before {
      font-size: 7px;
      padding-left: 2px;
      line-height: 12px;
      width: 12px !important;
      height: 12px !important;
      border: 1px solid #004583;
    }
  }
}
</style>
