<template>
    <div>
        <h4 class="font-weight-700 text-blue">{{ $t('downgrade.' + userLimits?.plan_type + '.actual_plan_include') }} {{
            userLimits.workspaces.limit }} {{ $t('downgrade.workspaces.' + (userLimits.workspaces.limit > 1 ? 'plural' :
                'singular')) }}</h4>
        <p class="my-6">{{ $t('downgrade.workspaces.keep') }}</p>
        <ValidationObserver v-slot="{ handleSubmit }">

            <form class="" @submit.prevent="handleSubmit(onSubmit)">
                <div class="d-flex flex-column">

                    <div v-for="workspaces in userLimits.workspaces.active" :key="workspaces.id">
                        <base-checkbox :disabled="userLimits.workspaces.limit === 0 ? true : false"
                            @change.native="selectItem(workspaces.id)" :value="workspaces.id"
                            :id="`workspaces_${workspaces.id}`">{{ workspaces.name
                            }}</base-checkbox>
                    </div>
                </div>
            </form>
        </ValidationObserver>

    </div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);

export default {
    name: "DowngradeWorkspaces",
    props: {
        userLimits: Object,
    },
    data() {
        return {
            form: {
                brand_voices: {
                    selected: [],
                    exceeded: this.userLimits.brand_voices.exceeded
                },
                user_seats: {
                    selected: [],
                    exceeded: this.userLimits.user_seats.exceeded
                },
                workspaces: {
                    selected: [],
                    exceeded: this.userLimits.workspaces.exceeded
                },
            }
        };
    },
    methods: {
        // onSubmit() {
        //     this.$emit('submit', { ...this.form });
        // },

        selectItem(id) {
            this.$emit('submit', { id }, 'workSpaces');
            // const index = this.form.workspaces.selected.indexOf(id);
            // if (index !== -1) {
            //     this.form.workspaces.selected = this.form.workspaces.selected.filter((id, idx) => idx !== index)
            // } else {
            //     this.form.workspaces.selected.push(id);
            // }
        }
    },
    computed: {
        // isFilled() {
        //     return this.form.workspaces.selected.length <= this.userLimits.workspaces.limit

        // },
    },
};

</script>