import axios from "@/axios";
import { apiHandler } from "@/util/errorHandling";

const getDefaultState = () => {
  return {
    folders: [], // used in move to folder select
    filteredFolders: [], // shown in Documents page table (active or trashed)
  };
};

const moduleFolders = {
  state: getDefaultState(),
  getters: {
    getFolders(state) {
      return state.folders;
    },
    getFilteredFolders(state) {
      return state.filteredFolders;
    },
    /* getNewFolder(state) {
      return state.newFolder
    } */
  },
  mutations: {
    setFolders(state, folders) {
      state.folders = folders;
    },
    setFilteredFolders(state, folders) {
      state.filteredFolders = folders.map((folder) => ({
        ...folder,
        isFolder: true,
      }));
    },
    setNewFolder(state, folder) {
      state.newFolder = folder;
    }
  },
  actions: {
    /**
     * Fetch folders data from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     * @param {number} params.workspace_id - The ID of the workspace.
     */
    async fetchFolders({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
        workspace_id: getters.selectedWorkspace?.id
      };

      const response = await axios.get("services/folders/show", {
        params: params,
      });

      const folders = response.data.response;
      commit("setFolders", folders);
    },

    /**
     * Create a new user seat and update Vuex store.
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - The parameters for creating the folder.
     * @param {string} params.name - The name of the folder.
     * @param {number} params.workspace_id - The ID of the workspace.
     */
    async createFolder({ getters, commit, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
        params.workspace_id = getters.selectedWorkspace?.id

        const response = await axios.put("services/folders/create", params);
        commit("setNewFolder", response.data.response);

        // Refresh Vuex store with the updated data
        await dispatch("fetchFolders");

        return response;
      });
    },

    /**
     * Move an order to an existing folder or to root
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} params - The parameters for creating the folder.
     * @param {number} params.order_id - The ID of the order
     * @param {number} params.folder_id - The ID of the folder (null to move in root folder).
     * @param {string} params.action - restore or trash.
     */
    async moveOrder({ getters }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        if (params.folder_id === null) {
          params.folder_id = "";
          // delete params.folder_id;
        }

        const response = await axios.post("services/orders/move", params);

        return response;
      });
    },

    /**
     * Update an existing folder and update Vuex store.
     *
     * @async
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} params - The parameters for creating the folder.
     * @param {number} params.folder_id - The ID of the folder (null to move in root folder).
     * @param {string} params.action - restore or trash.
     */
    async updateFolder({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.post("services/folders/update", params);

        // Refresh Vuex store with the updated data
        await dispatch("fetchFolders");

        return response;
      });
    },

    /**
     * Delete a folder and update Vuex store.
     *
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {number} folderId - The ID of the folder entry to be deleted.
     */
    async deleteFolder({ getters, dispatch }, folderId) {
      await apiHandler(async () => {
        const user = getters.getUser;
        const params = {
          user_id: user.id,
          folder_id: folderId,
        };

        const response = await axios.delete("services/folders/delete", {
          params: params,
        });

        // Refresh Vuex store with the updated data
        await dispatch("fetchFolders");

        return response;
      });
    },
  },
};

export default moduleFolders;
