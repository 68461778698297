export default {
    step(state){
        return state.step
    },
    totalSteps(state){
        return state.totalSteps
    },
    customerType(state){
        return state.customerType
    },
    appLanguages(state) {
        return state.appLanguages;
    },
    currentLangKey(state) {
        return state.currentLangKey;
    },
    freeTrial(state) {
        return state.freeTrial;
    },
    emailMatch(state) {
        return state.emailMatch;
    },
    userEmail(state) {
        return state.userEmail;
    },
    confirmEmail(state) {
        return state.confirmEmail;
    },
    valid(state) {
        return state.valid;
    },
    form(state) {
        return state.form;
    },
    freeTrialFinalAgreement(state) {
        return state.freeTrialFinalAgreement;
    },
    
}