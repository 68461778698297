import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";

const getDefaultState = () => {
  return {
    websites: []
  };
};

const moduleWebsites = {
  state: getDefaultState(),
  getters: {
    getWebsites(state) {
      return state.websites;
    },
    canCreateWebsite(state, getters) {
      return (
        getters.getUserRole != 'user'
      );
    },
  },
  mutations: {
    clearModuleWebsites(state) {
      Object.assign(state, getDefaultState());
    },
    setWebsites(state, websites) {
      state.websites = websites;
    },
  },
  actions: {
    async fetchWebsites({ getters, commit }, workspace_id) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
        workspace_id: workspace_id ?? null
      };

      const response = await axios.get("features/websites/show", {
        params: params,
      });

      const websites = response.data.websites;

      //websites.sort((a, b) => a.name.localeCompare(b.name));

      // Update Vuex store with the retrieved data
      commit("setWebsites", websites);
    },


    async createWebsite({ getters, commit, dispatch }, params) {
      return await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
        params.workspace_id = getters.selectedWorkspace?.id

        let response = await axios.put("features/websites/create", params);

        // Refresh Vuex store with the updated data
        await dispatch("fetchWebsites", getters.selectedWorkspace?.id);
        return response;
      });

      // Reset analizedText state

    },

    async updateWebsite({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.post("features/websites/edit", params);

        // Refresh Vuex store with the updated data
        await dispatch("fetchWebsites", getters.selectedWorkspace?.id);

        console.log('response', response)
        return response;
      });
    },

    async deleteWebsite({ getters, dispatch }, id) {
      await apiHandler(async () => {
        const user = getters.getUser;
        const params = {
          user_id: user.id,
          website_id: id,
        };

        const response = await axios.delete("features/websites/delete", {
          params: params,
        });

        // Refresh Vuex store with the updated data
        await dispatch("fetchWebsites", getters.selectedWorkspace?.id);

        return response;
      });
    },

    async publishWebsite({ getters }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
        //params.workspace_id = getters.selectedWorkspace?.id

        const response = await axios.post("features/websites/publish", params);

        //console.log('response', response)
        return response;
      });
    },

  },
};

export default moduleWebsites;
