import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";

const getDefaultState = () => {
  return {
    notificationGroups: [],
  };
};

const moduleNotificationGroups = {
  state: getDefaultState(),
  getters: {
    getNotificationGroups(state) {
      return state.notificationGroups;
    },
  },
  mutations: {
    clearModuleNotificationGroups(state) {
      Object.assign(state, getDefaultState());
    },
    setNotificationGroups(state, notificationGroups) {
      state.notificationGroups = notificationGroups;
    },
  },
  actions: {

    async fetchNotificationGroups({ getters, commit }) {
      // Get the currently authenticated user
      const user = getters.getUser;

      if (user) {
        const queryParams = {
          user_id: user.id,
        };

        // Fetch keywords based on the specified keyword type
        let response;
        response = await axios.get("features/notification-groups/show", {
          params: queryParams,
        });

        if (response) {
          const notificationGroups = response.data.notification_groups;

          commit("setNotificationGroups", notificationGroups);

          return;
        }
      }

      throw new Error("Failed to fetch notification groups");
    },

    async editNotificationGroups({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
  
        const response = await axios.post("features/notification-groups/edit", params);
        await dispatch("fetchNotificationGroups");
        return response;
      });
    },

    async addUsersNotificationGroup({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
  
        const response = await axios.post("features/notification-groups/add", params);
        await dispatch("fetchNotificationGroups");
        return response;
      });
    },
  },
};

export default moduleNotificationGroups;
