const setUTMParamsInLocalStorage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};
    const keysToRetrieve = ["utm_campaign", "utm_medium", "utm_source","utm_content"];
    console.log('success entering in utmparams func');
    for (const [key, value] of urlParams) {
      params[key] = value;
    }

    for (const key in params) {
      if (params.hasOwnProperty(key) && keysToRetrieve.includes(key)) {
        localStorage.setItem(key, params[key]);
      }
    }
  }
  
  export default setUTMParamsInLocalStorage