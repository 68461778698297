<template>
    <div>
        <h4 class="font-weight-700 text-blue">{{ $t('downgrade.' + userLimits?.plan_type + '.actual_plan_include') }} {{
            userLimits.brand_voices.limit }} {{ $t('downgrade.brand_voices.' + (userLimits.brand_voices.limit > 1 ?
                'plural'
                :
                'singular')) }}</h4>
        <p class="my-6">{{ $t('downgrade.brand_voices.keep') }}</p>
        <ValidationObserver v-slot="{ handleSubmit }">

            <form class="" @submit.prevent="handleSubmit(onSubmit)">
                <div class="d-flex flex-column">

                    <div v-for="brandVoice in userLimits.brand_voices.active" :key="brandVoice.id">
                        <base-checkbox @change.native="selectItem(brandVoice.id)" :value="brandVoice.id"
                            :id="`brandVoice_${brandVoice.id}`">{{ brandVoice.name
                            }}</base-checkbox>
                    </div>
                </div>
            </form>
        </ValidationObserver>

    </div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);

export default {
    name: "DowngradeBrandVoices",
    props: {
        userLimits: Object,
    },
    data() {
        return {
            form: {
                brand_voices: {
                    selected: [],
                    exceeded: this.userLimits.brand_voices.exceeded
                },
                user_seats: {
                    selected: [],
                    exceeded: this.userLimits.user_seats.exceeded
                },
                workspaces: {
                    selected: [],
                    exceeded: this.userLimits.workspaces.exceeded
                },
            }
        };
    },
    methods: {
        // onSubmit() {
        //     this.$emit('submit', { ...this.form });
        // },

        selectItem(id) {
            this.$emit('submit', { id }, 'brandVoices');
            // const index = this.form.brand_voices.selected.indexOf(id);
            // if (index !== -1) {
            //     this.form.brand_voices.selected = this.form.brand_voices.selected.filter((id, idx) => idx !== index)
            // } else {
            //     this.form.brand_voices.selected.push(id);
            // }
        }
    },
    computed: {
        // isFilled() {
        //     return this.form.brand_voices.selected.length <= this.userLimits.brand_voices.limit

        // },
    },
};

</script>