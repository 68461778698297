<template>
  <footer class="footer d-flex justify-content-center">
    <div class="copyright">
      {{ $t("footer.copyright") }} &copy; {{ year }}
      <a href="https://www.contents.ai/" target="_blank" rel="noopener" class="font-weight-bold">{{ $t("footer.site")
        }}</a>
      {{ $t("footer.right_reserved") }}
      <p class="text-center font-weight-bold">{{ $t("footer.version") }}</p>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
