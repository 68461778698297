var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.walletInfoObj)?_c('div',{staticClass:"wallet"},[(_vm.showCreditsPanel)?_c('div',{staticClass:"wallet-info"},[_c('span',{staticClass:"wallet-title"},[_vm._v("Credits")]),_c('SidebarWalletCell',{attrs:{"title":'AI',"credits":_vm.walletInfoObj?.userIsFTFlow ||
        _vm.walletInfoObj?.activePlanPackage?.plan_type === 'advanced' ||
        _vm.walletInfoObj?.activePlanPackage?.plan_type === 'intermediate'
          ? 'Unlimited'
          : _vm.walletInfoObj?.aiCredits,"paymentUrl":_vm.walletInfoObj?.isAiCreditsUnlimited ? '' : this.paymentUrl}}),_c('div',{staticClass:"divider"}),(_vm.walletInfoObj?.extraCredits > 0)?_c('SidebarWalletCell',{attrs:{"title":'Extra',"credits":_vm.walletInfoObj?.extraCredits,"paymentUrl":_vm.walletInfoObj?.isAiCreditsUnlimited ? '' : this.paymentUrl}}):_vm._e(),(_vm.walletInfoObj?.extraCredits > 0)?_c('div',{staticClass:"divider"}):_vm._e(),(!_vm.isDemoUser && !_vm.walletInfoObj?.userIsFTFlow)?_c('SidebarWalletCell',{attrs:{"title":'Human',"credits":_vm.walletInfoObj?.walletAmount + ' ' + _vm.walletInfoObj?.currencySymbol,"kind":'human',"paymentUrl":this.paymentUrl + '?human=1'}}):_vm._e(),(
        !_vm.walletInfoObj?.activePlanPackage ||
        _vm.walletInfoObj?.activePlanPackage?.plan_type !== 'advanced' ||
        (_vm.walletInfoObj?.activePlanPackage?.plan_type === 'advanced' &&
          !_vm.walletInfoObj?.yearlySub)
      )?_c('SidebarUpgradeButton',{attrs:{"paymentUrl":this.paymentUrl}}):_vm._e()],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }