export default {
  baseURL: process.env.VUE_APP_DASHBOARD_APP_BASE_URL || "https://dashboard.contents.com/api/",
  colors: {
    default: "#344675",
    primary: "#42b883",
    info: "#1d8cf8",
    danger: "#fd5d93",
    orange: "#ff8a76",
    teal: "#00d6b4",
    primaryGradient: [
      "rgba(76, 211, 150, 0.1)",
      "rgba(53, 183, 125, 0)",
      "rgba(119,52,169,0)",
    ],
    purpleGradient: ["rgba(253,93,147,0.8)", "rgba(253,93,147,0)"],
  },
  stripePublicKey: process.env.VUE_APP_STRIPE_KEY,
  googleRecaptchaKey: process.env.VUE_APP_RECAPTCHA,
  isGoogleRecaptchaActive: process.env.VUE_APP_RECAPTCHA_ACTIVE || false,
  userPilotId: process.env.VUE_APP_USER_PILOT_ID ,
  cookieDomain: process.env.VUE_APP_COOKIE_DOMAIN,
  aiArtUrl: process.env.VUE_APP_AI_ART_URL,
};
