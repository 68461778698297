import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
/**
 * 
 * @param {Number} value 
 * @param {Number} min 
 * @returns 
 */

export function hasMinLength(value, min) {
    return value.length >= min;
}
/**
 * 
 * @param {String} value 
 * @returns 
 */
export function hasCapital(value) {
    const regEx = new RegExp('^(?=.*?[A-Z])');
    return regEx.test(value);
}
/**
 * 
 * @param {String} value 
 * @returns 
 */
export function hasLowerCase(value) {
    const regexp = /[a-z]/;

    return regexp.test(value);
}
/**
 * 
 * @param {String} value 
 * @returns 
 */
export function hasDigit(value) {
    const regEx = new RegExp('^(?=.*?[0-9])');
    return regEx.test(value);
}
/**
 * 
 * @param {String} value 
 * @returns 
 */
export function hasSpecialChars(value) {
    const regEx = new RegExp('^(?=.*?[!?@#$%^&*`~.+_-])');
    return regEx.test(value);
}
/**
 * 
 * @param {String} value 
 * @returns 
 */
export function isValidVat(value) {
    const regEx = /^(?:[a-zA-Z]{0,4})(?=\d{0,11}$)/; 
    return regEx.test(value);
}

export function isValidFiscalCode(value) {
    const regexCF = /[A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1}/;
    if (!value || !value.length) {
        return false;
    }
    if (value && (value.length > 16 || !value.length)) {
        return false;
        //return 'This field is longer than 16 characters';
    }
    if (value && !value.match(regexCF)) {
        return false;
        //return 'Fiscal code is invalid';
    }
    return true;
}
const euCountries=['AT',                                   // Austria
'BE',                                   // Belgium
'BG',                                 // Bulgaria 
'CY',                        // Cyprus
'CZ',                                // Czech Republic
'DE',                               // Germany 
'DK',                                    // Denmark 
'EE',                                  // Estonia 
'EL',                                    // Greece 
'ES',                 // Spain
'EU',                                    // EU-type 
'FI',                                    // Finland 
'FR',                      // France
'GB', // UK (Standard = 9 digits), (Branches = 12 digits), (Government = GD + 3 digits), (Health authority = HA + 3 digits)
'GR',                                 // Greece 
'HR',                                // Croatia 
'HU',                                  // Hungary 
'IE',               // Ireland
'IT',                                   // Italy 
'LV',                                 // Latvia 
'LT',                           // Lithunia
'LU',                                    // Luxembourg 
'MT',                               // Malta
'NL',                              // Netherlands
'NO',                                   // Norway (not EU)
'PL',                                  // Poland
'PT',                                    // Portugal
'RO',                            // Romania
'RU',                         // Russia
'RS',                                 // Serbia
'SI',                               // Slovenia
'SK',               // Slovakia Republic
'SE'                                 // Sweden
]

export function isoCodeFromEu(value){
    return value &&  euCountries.includes(value.toUpperCase());
}


/**
 * 
 * @param {String} value 
 * @returns 
 */
export function isValidPassword(value) {
    //return hasMinLength(value, 8) && hasCapital(value) && hasDigit(value) && hasSpecialChars(value);
    return hasMinLength(value, 8) && hasLowerCase(value) && hasCapital(value)  && hasSpecialChars(value);
}
/**
 * 
 * @param {String} value 
 * @returns  Boolean
 */
export function isValidEmail(email) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email);
}

export function isValidPecOrSdi(value){
    if (value === "XXXXXXX" || value === "0000000") {
        return true;
      }
    const sdiRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{1,7}$/;
    if (value && value.includes("@")) {
      if (!isValidEmail(value)) {
        return false;
      }
    }

    if (sdiRegex.test(value) && value.length < 7) {
      return false;
    }

    if (!value.includes("@") && !sdiRegex.test(value)) {
      return false;
    }

    return true;
}



export const customValidators = {
    hasMinLength,
    hasCapital,
    hasLowerCase,
    hasDigit,
    hasSpecialChars,
    isValidPassword,
    isValidEmail,
    isValidPecOrSdi
}