import axios from "@/axios";
import { apiHandler } from "@/util/errorHandling";

const getDefaultState = () => {
  return {
    serviceType: 'ai',
    billingType: 'year',
    pricingPlans:[],
    userSeats:{},
    aiCreditsPack:{},
    humanCreditsPack:{},
    userCurrency:{},
    pricingServices:{}
  };
};

const modulePricing = {
  state: getDefaultState(),
  namespaced:true,
  getters: {
    getServiceType(state) {
        return state.serviceType;
    },
    getBillingType(state) {
        return state.billingType;
    },
    getPricingPlans(state) {
        return state.pricingPlans;
    },
    getUserSeats(state) {
        return state.userSeats;
    },
    getAiCreditsPack(state) {
        return state.aiCreditsPack;
    },
    getHumanCreditsPack(state) {
        return state.humanCreditsPack;
    },
    getUserCurrency(state) {
        return state.userCurrency;
    },
    getPricingServices(state) {
        return state.pricingServices;
    },

  },
  mutations: {
    setServiceType(state, serviceType) {
        state.serviceType = serviceType;
    },
    setBillingType(state, billingType) {
        state.billingType = billingType;
    },
    setPricingPlans(state, pricingPlans){
        state.pricingPlans = pricingPlans
    },
    setUserSeats(state, userSeats){
        state.userSeats = userSeats
    },
    setAiCreditsPack(state, aiCreditsPack){
        state.aiCreditsPack = aiCreditsPack
    },
    setHumanCreditsPack(state, humanCreditsPack){
        state.humanCreditsPack = humanCreditsPack
    },
    setUserCurrency(state, userCurrency){
        state.userCurrency = userCurrency
    },
    setPricingServices(state, pricingServices){
        state.pricingServices = pricingServices
    },
  },
  actions: {
    updateServiceType({ commit }, {serviceType,currency,yearlyPlans}){
        // console.log(yearlyPlans)
        let category
        let pricingPlans
        if (serviceType == 'ai') {
            category = 'Yearly'
            pricingPlans = yearlyPlans
        } else {
            category = 'Recharge'
            pricingPlans = null
        }

        // window.gtm?.ec_view_item(
        //     serviceType,
        //     category,
        //     currency,
        //     pricingPlans
        // );

        commit('setServiceType', serviceType)
    },
    updateBillingType({ commit }, {billingType}){
        commit('setBillingType', billingType)
    },
    async fetchPricings({ commit }){
        await apiHandler(async () => {
            const response = await axios.get("payments/get-pricing-plans");
            // response.data.pricingPlans

            const {pricingPlans, userSeats,aiCreditsPack, humanCreditsPack , userCurrency} = response.data
            
            const pricingPlansFiltered = pricingPlans.filter(plan => !plan.type.startsWith('B'));

            console.log(pricingPlansFiltered)
            console.log(response)

            commit('setPricingPlans', pricingPlansFiltered)
            commit('setUserSeats', userSeats)
            commit('setAiCreditsPack', aiCreditsPack)
            commit('setHumanCreditsPack', humanCreditsPack)
            commit('setUserCurrency', userCurrency)
            return response;

          }, false);
    },
    async fetchHumanPricings({ commit }){
        await apiHandler(async () => {
            const response = await axios.get("payments/get-human-pricing");
            commit('setPricingServices', response.data.price)
            return response

          }, false);
    }
  },
};

export default modulePricing;
