export default {
    getCart(state){
        return state.cart;
    },
    getCartType(state){
        return state.cartType;
    },
    getPricePer50AICredits(state){
        return state.pricePer50AICredits
    },
    calculateTotalProductPrices(state){
        
       return  state.cart.map(product => {
        if(product.startingPrice){
            return product.startingPrice*product.quantity
        } else {
            if(product.purchaseType === 'human-one-shot'){
                return (product.value / 100)*product.quantity
            }
           return  (product.value / 100) * product.quantity;
        }
        
    }).reduce((acc,elem)=>{
            return acc+ elem
        },0)

    },
    calculateAmountOff(state, getters, rootState, rootGetters){
        const discounts = rootGetters['payments/discounts']
        if(!discounts.id){
            return 0;
        }
        const total = getters['calculateTotalProductPrices']
        const isPercentage = discounts.is_percentage
        if (isPercentage) {
            return ((total / 100) * discounts.percent_off).toFixed(2);
        }
        return (discounts.amount_off).toFixed(2);
        
    },
    calculateTotal(state, getters){
        const total = getters['calculateTotalProductPrices']
        const amountOff = getters['calculateAmountOff']
        const prorationAmount = getters['getProrationAmount']
        let tot = parseFloat((total - amountOff - prorationAmount))
        if(tot < 0){
            tot = 0
        }
        return tot.toFixed(2);
    },
    getProrationAmount(state){
        return state.prorationAmount
    }
}