export default {
    addToCart(state, product) {
        let productInCartIndex = state.cart.findIndex(item => item.id === product.id);
        if (productInCartIndex !== -1) {
            state.cart[productInCartIndex].quantity++;
            return;
        }
        product.quantity = 1;
        state.cart.push(product);
    },
    removeFromCart(state, index) {
        state.cart.splice(index, 1);
    },
    updateCart(state, cart) {
        state.cart = cart;
    },
    updateCartType(state, cartType){
        state.cartType = cartType
    },
    setPricePer50AICredits(state, pricePer50AICredits){
        state.pricePer50AICredits = pricePer50AICredits
    },
    clear(state){
        state.cart=[];
        state.cartType= null;
        state.pricePer50AICredits= 0.30;
        state.amountOff = 0;
        state.prorationAmount = 0;
    },
    setProrationAmount(state, prorationAmount){
        state.prorationAmount = prorationAmount
    },
    setProductQuantity(state, quantity){
        // must destructure and reassign the whole cart 
        // to preserve reactivity 
        let cart = [...state.cart];
        cart[0].quantity = quantity
        state.cart= cart
    }
}