 import axios from "@/axios"; 

export default {
    clearCart({commit}) {
        commit('updateCart', []);
    },
    async fetchProrationAmount({commit, getters }) {
        const cart = getters['getCart'];
        //If the cart contains a main plan
        if(cart.length > 0 && cart[0].plan_package_id){
            const type = cart[0].type;
            try {
                const {data} = await axios.get('payments/proration-amount', {
                    params: { plan_type: type } 
                });
                commit('setProrationAmount', data.value);
            } catch (error) {
                console.log(error);
            }  
        }
    },
    updateProductQuantity({commit, rootGetters}, quantity){
        let qty = quantity
        const {activePlanPackage} = rootGetters['walletInfoObj']
        const maxSeats =  activePlanPackage.max_upsell_user_seat
        if(maxSeats === 'unlimited'){
            commit('setProductQuantity', quantity);
            return
        } 
        if(qty < 0){
            qty = 0
        } else if(qty > maxSeats){
            qty = maxSeats
        }
        commit('setProductQuantity', qty);
    }
}