import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";

const getDefaultState = () => {
  return {
    pageurl: null,
    pageurls: [],
    totalPageUrlsNum: 0,
  };
};

const modulePageUrl = {
  state: getDefaultState(),
  getters: {
    getPageUrl(state) {
      return state.pageurl;
    },
    getPageUrls(state) {
      return state.pageurls;
    },
    getTotalPageUrlsNum(state) {
      return state.totalPageUrlsNum;
    },
  },
  mutations: {
    clearModulePageUrl(state) {
      Object.assign(state, getDefaultState());
    },
    setPageUrl(state, pageurl) {
      state.pageurl = pageurl;
    },
    setPageUrls(state, pageurls) {
      state.pageurls = pageurls;
    },
    setTotalPageUrlsNum(state, totalPageUrlsNum) {
      state.totalPageUrlsNum = totalPageUrlsNum;
    },
  },
  actions: {

    async createPageUrl({ getters, dispatch }, params) {
      return await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;
        params.source = 'saas';

        const formData = new FormData();
        for (let key in params) {

          if (params.hasOwnProperty(key)) {
            let value = params[key];
            console.log(value)
            formData.append(key, value);

            /* if (Array.isArray(value)) {
              value.forEach((item) => formData.append(key+'[]', item))
            }
            else {
              // Parse boolean to integer
              if (typeof value === 'boolean') {
                value = value ? 1 : 0;
              }
            } */
          }
        }

        let response = await axios.post("tools/page_url", formData);

        // Refresh Vuex store with the updated data
        await dispatch("fetchPageUrls");
        return response;
      });

    },


    async fetchPageUrls({ getters, commit }, params = {}) {
      // Get the currently authenticated user
      const user = getters.getUser;

      if (user) {
        const queryParams = {
          user_id: user.id,
          ...params,
        };

        // Fetch pageurls based on the specified pageurl type
        let response;
        response = await axios.get("tools/page_url", {
          params: queryParams,
        });

        if (response) {
          const totalPageUrlsNum = response.data.recordsTotal;
          const pageurls = response.data.pageUrls;

          // Update Vuex store with fetched pageurls and total count
          commit("setPageUrls", pageurls);
          commit("setTotalPageUrlsNum", totalPageUrlsNum);

          return;
        }
      }

      throw new Error("Failed to fetch pageurls");
    },

    async updatePageUrl({ getters, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.patch("tools/page_url", params);
        await dispatch("fetchPageUrls");
        return response;
      });
    },

    async deletePageUrl({ getters, dispatch }, id) {
      await apiHandler(async () => {
        const user = getters.getUser;
        const params = {
          user_id: user.id,
          id: id,
        };

        const response = await axios.delete("tools/page_url", {
          params: params,
        });

        // Refresh Vuex store with the updated data
        await dispatch("fetchPageUrls");

        return response;
      });
    },

  },
};

export default modulePageUrl;
