import axios from "@/axios";
import router from "@/routes/router";
import { Message } from "element-ui";
import { apiHandler } from "@/util/errorHandling";

const getDefaultState = () => {
  return {
  };
};

const moduleReferral = {
  state: getDefaultState(),
  getters: {
  },
  mutations: {
  },
  actions: {
    async sendInvitationEmail({ getters }, params) {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.post("/features/affiliation/invitation_email", params);
        return response
    },

    async createPayoutRequest({ getters }, params) {
      const user = getters.getUser;
      params.user_id = user.id;

      const response = await axios.post("/features/affiliation/create_request_payout", params);
      return response
    },

    async addPayoutMethod({ getters }, params) {
      const user = getters.getUser;
      params.user_id = user.id;

      const response = await axios.post("/features/affiliation/add_payout_method", params);
      return response
    },

    async getPayoutsData({ getters }) {
      const user = getters.getUser;
      const params = {
        user_id : 1204
      }
      const response = await axios.get("/features/affiliation/get_user_payouts", {params});
      return response
    },
    async getReferralsData({ getters }) {
      const user = getters.getUser;
      const params = {
        user_id : 1204
      }
      const response = await axios.get("/features/affiliation/get_user_commissions", {params});
      return response
    }, 
  },
  
};

export default moduleReferral;
