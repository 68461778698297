<template>
  <base-nav v-model="showMenu" class="navbar-absolute navbar-absolute--auth top-navbar" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <router-link to="/dashboard" class="navbar-brand logo-dashboard">
        <img
          src="/img/brand/contents_ai_blue.svg"
          alt=""
          class="main-logo"
          style="width: 165px; height: 22px"
        >
      </router-link>
    </div>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import { Select, Option } from 'element-ui';
import { getDarkMode, setDarkMode } from "../../services/localService";

export default {
  components: {
    BaseNav,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      darkMode: getDarkMode(),
      workspaceSelected: 'personal',
      workspaceOptions: [
        { value: 'personal', label: 'Personal' },
        { value: 'business', label: 'Business' },
      ],
      languageSelected: 'EN',
      languageOptions: [
        { value: 'EN' },
        { value: 'IT' },
        { value: 'DE' },
        { value: 'ES' },
        { value: 'FR' },
        { value: 'BR' },
      ],
      searchTool: '',
      tools: [],
      selectedTool: null
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    avatar() {
      return this.$store.getters.getAvatar;
    },
    hasWorkspace() {
      return this.$store.getters.getHasWorkspace;
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleMode(type) {
      setDarkMode(type);
      let docClasses = document.body.classList;
      if (type) {
        docClasses.remove('white-content');
      } else {
        docClasses.add('white-content');
      }
    },
    logout() {
      this.$store.dispatch('logout');
    },
    searchToolByInput() {
      this.$store.commit('searchToolByInput', this.searchTool)
      console.log(this.$store.state.count)

    }
  },
  created() {
    this.tools = this.$store.state.tools.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB)
        return -1;
      if (nameA > nameB)
        return 1;
      return 0;
    });
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
